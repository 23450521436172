import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from '@merchant-portal/app/core/storage/local-storage.service';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import {
  ITeamMemberData,
  ITeamMemberDataSubmit,
  Meta,
  TeamMemberFilter,
} from '@merchant-portal/app/models/setting/team-member.interface';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TeamMemberStoreFactory } from '../factory/team-member-store';
import { userListAction } from '../store/action';
import { userlistFeature } from '../store/reducer';

@Injectable()
export class TeamMemberStore implements TeamMemberStoreFactory {
  private store: Store = inject(Store);
  private localStorageService = inject(LocalStorageService);
  users$: Observable<ITeamMemberData[]> = this.store.select(
    userlistFeature.selectTeamMemberList,
  );

  roles = this.store.select(userlistFeature.selectAllRole);

  meta$: Observable<Meta | undefined> = this.store.select(
    userlistFeature.selectMeta,
  );

  userDetail$: Observable<ITeamMemberData | undefined> = this.store.select(
    userlistFeature.selectDetailUser,
  );

  isLoading$: Observable<boolean> = this.store.select(
    userlistFeature.selectRequestUserListLoading,
  );

  isTeamMemberDialogClose$: Observable<boolean> = this.store.select(
    userlistFeature.selectIsTeamMemberDialogClose,
  );

  hasPermission(permission: string): boolean {
    return Boolean(
      this.store
        .selectSignal(dashboardFeature.selectPermissionList)()
        ?.includes(permission),
    );
  }

  canResendEmailInvitation(props: { email: string; createdAt?: string }) {
    try {
      const emailHistoryLog =
        this.localStorageService.getItem(
          'canResendEmailInvitation' + props.email,
        ) ||
        (props.createdAt
          ? new Date(props.createdAt).getTime().toString()
          : null);
      console.log(emailHistoryLog, props);
      if (emailHistoryLog) {
        const timeStamp = JSON.parse(emailHistoryLog);
        const now = new Date();
        const diff = now.getTime() - timeStamp;
        const diffSeconds = Math.floor(diff / 1000);
        if (diffSeconds <= 180) {
          return false;
        }
      }
      return true;
    } catch (error) {
      return true;
    }
  }

  addEmailInvitationLog(props: { email: string }) {
    const now = new Date();
    localStorage.setItem(
      'canResendEmailInvitation' + props.email,
      JSON.stringify(now.getTime()),
    );
  }

  requestAllUsers(filter?: TeamMemberFilter): void {
    this.store.dispatch(userListAction.requestAllUsers(filter));
  }

  showAddUserDialog(): void {
    this.store.dispatch(userListAction.showAddUserDialog());
  }

  showEditUserDialog(id: string): void {
    this.store.dispatch(userListAction.showUpdateUserDialog(id));
  }

  requestDetailUsers(id: string): void {
    this.store.dispatch(userListAction.requestDetailUser(id));
  }

  requestCreateTeamMember(body: ITeamMemberDataSubmit): void {
    this.store.dispatch(userListAction.requestCreateTeamMember(body));
  }

  requestEditTeamMember(body: ITeamMemberDataSubmit): void {
    this.store.dispatch(userListAction.requestEditTeamMember(body));
  }

  showActive(id: string): void {
    this.store.dispatch(userListAction.requestActiveUser(id));
  }
  showDeactive(id: string): void {
    this.store.dispatch(userListAction.requestDeactiveUser(id));
  }
  showResendInvitation(body: ITeamMemberData): void {
    if (this.canResendEmailInvitation(body)) {
      this.addEmailInvitationLog({ email: body.email });

      this.store.dispatch(userListAction.requestResendInvitationUser(body));
    }
  }

  requestAllRoles(): void {
    if (
      this.store
        .selectSignal(dashboardFeature.selectPermissionList)()
        ?.includes('roles-and-permissions.view')
    )
      this.store.dispatch(userListAction.requestAllPermissions());
  }
}
