import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
export interface OtpParams {
  otp: string;
  event: string;
}

export interface OtpState {
  errorMessage: string;
  isLoading: boolean;
  countdown: number;
  isCountdownActive: boolean;
  isAccountBlocked: boolean;
  isSuspendAccount: boolean;
  isEventFromInvitation: boolean;
  isVeritifyOtpSuccess?: boolean;
  otpToken: string;
}
@Injectable()
export abstract class OtpStoreFactory {
  abstract vm$: Observable<OtpState>;
  abstract verifyOtp(otpParams: OtpParams): void;
  abstract resendOtp(event: string): void;
  abstract startCountdown(): void;
  abstract getEmail(): string;
  abstract resetState(): void;
}
