import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserAccessDirective } from '@merchant-portal/app/core/user-access/user-access-directive';
import { BreadcrumbComponent } from 'projects/merchant-portal/src/app/components/breadcrumb/breadcrumb.component';
import { BulkTransactionModule } from './bulk-transaction/bulk-transaction.module';
import { CreateTransactionComponent } from './create-transaction.component';
import { CreateTransactionRoutingModule } from './create-transaction.routing.module';
import { SingleTransactionModule } from './single-transaction/single-transaction.module';

@NgModule({
  declarations: [CreateTransactionComponent],
  imports: [
    CommonModule,
    SingleTransactionModule,
    CreateTransactionRoutingModule,
    BulkTransactionModule,
    RouterModule,
    BreadcrumbComponent,
    UserAccessDirective,
  ],
})
export class CreateTransactionModule {}
