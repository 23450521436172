import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MESSAGE_TYPE } from './message.constant';
import { ToastMessageFactory } from './toast-message-factory.service';

@Injectable()
export class ToastMessageService extends ToastMessageFactory {
  constructor(private messageService: MessageService) {
    super();
  }

  postMessage(body: {
    type: MESSAGE_TYPE;
    variant?: string;
    title: string;
    body: string;
  }) {
    this.messageService.add({
      key: body.type.toString(),
      severity: body.variant,
      data: {
        body: body.body,
        title: body.title,
      },
    });
  }
}
