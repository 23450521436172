import { environment } from '@merchant-portal/environments/environment';
import { RxStompConfig } from '@stomp/rx-stomp';

export const myRxStompConfig: RxStompConfig = {
  // Which server?
  brokerURL: environment.stomp.brokerURL,
  connectHeaders: {
    login: environment.stomp.login,
    passcode: environment.stomp.passcode,
  },
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 5000, // Typical value 20000 - every 20 seconds
  reconnectDelay: 1000,
  debug: (msg: string): void => {},
};
