import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appReplaceString',
  standalone: true,
})
export class ReplaceStringPipe implements PipeTransform {
  transform(value: string, data: any): string {
    let newValue = value;
    for (const [key, value] of Object.entries(data)) {
      newValue = newValue.replaceAll(`{${key}}`, value as string);
    }
    return newValue;
  }
}
