<div class="title-holder">
  <span class="title-text">{{ title }}</span>
  <span class="subtitle-text">{{ subtitle }}</span>
</div>
<div class="form-holder">
  <label for="">{{ formLabel }}</label>
  <input
    [ngModel]="formValue"
    (ngModelChange)="onChange($event)"
    [type]="store?.inputType"
    maxlength="6"
    [ngClass]="{
      'invalid-form': shouldShowError()
    }"
  />
  <p class="warning-message" *ngIf="shouldShowError()">
    <i class="pi pi-exclamation-triangle"></i>
    {{ errorText() }}
  </p>
</div>
<div class="wrap-button">
  <button [disabled]="!isValid" (click)="close()" class="pg-button">
    {{ buttonText }}
  </button>
</div>
