import { Injectable } from '@angular/core';
import { environment } from '@merchant-portal/environments/environment';

type flag = typeof environment.flag;

@Injectable({
  providedIn: 'root',
})
export class EnvFlagService {
  getFlag(key: keyof flag) {
    return environment.flag[key];
  }
}
