import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, tap, withLatestFrom } from 'rxjs';
import { AuthService } from '../auth.service';
import { LoginInvitationApiService } from './services/login-invitation.api.service';

export interface LoginInvitationState {
  step: 'password' | 'pin' | 'otp';
  isLoading: boolean;
  errorMessage: string | null;
  token: string | null;
  email: string | null;
  pin: string | null;
  password: string | null;
  startOtp: boolean;
}

export interface ActivateUser {
  email: string;
  password: string;
  pin: string;
}

export interface ValidateInvitationPayload {
  token: string;
}
export interface ValidateInvitationResponse {
  data: {
    email: string;
  };
}

@Injectable()
export class LoginInvitationStore extends ComponentStore<LoginInvitationState> {
  constructor(
    private apiService: LoginInvitationApiService,
    private authService: AuthService,
    private router: Router
  ) {
    super({
      step: 'password',
      isLoading: false,
      errorMessage: null,
      token: '123',
      email: null,
      pin: null,
      password: null,
      startOtp: false,
    });
    this.email$.subscribe((email) => {
      if (email) {
        this.authService.setEmail(email);
      }
    });
  }

  readonly step$ = this.select((state) => state.step);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly errorMessage$ = this.select((state) => state.errorMessage);
  readonly token$ = this.select((state) => state.token);
  readonly pin$ = this.select((state) => state.pin);
  readonly startOtp$ = this.select((state) => state.startOtp);
  readonly email$ = this.select((state) => state.email);

  readonly setStep = this.updater(
    (state, step: 'password' | 'pin' | 'otp') => ({
      ...state,
      step,
    })
  );

  readonly setLoading = this.updater((state, isLoading: boolean) => ({
    ...state,
    isLoading,
  }));

  readonly setToken = this.updater((state, token: string | null) => ({
    ...state,
    token,
  }));

  readonly setEmail = this.updater((state, email: string | null) => ({
    ...state,
    email,
  }));

  readonly setPin = this.updater((state, pin: string | null) => ({
    ...state,
    pin,
  }));

  readonly setPassword = this.updater((state, password: string | null) => ({
    ...state,
    password,
  }));

  readonly setErrorMessage = this.updater(
    (state, errorMessage: string | null) => ({
      ...state,
      errorMessage,
    })
  );

  readonly setStartOtp = this.updater((state, startOtp: boolean) => ({
    ...state,
    startOtp,
  }));

  readonly verifyLinkInvitation = this.effect(
    (payload$: Observable<ValidateInvitationPayload>) =>
      payload$.pipe(
        tap(() => this.setLoading(true)),
        tap((token) => {
          this.apiService
            .verifyLinkInvitation(token)
            .pipe(
              tap({
                next: (res) => {
                  this.setEmail(res.data.email);
                  this.setLoading(false);
                  this.setErrorMessage(null);
                },
                error: (error) => {
                  this.setLoading(false);
                  this.checkAccessToken();
                  this.setErrorMessage(error.message);
                },
              })
            )
            .subscribe();
        })
      )
  );

  readonly activateUser = this.effect((payload$: Observable<void>) =>
    payload$.pipe(
      withLatestFrom(this.state$),
      tap(([, state]) => {
        const { token, email, password, pin } = state;
        const activateToken = this.authService.getTempToken() ?? '';
        console.log(state);

        if (!token || !email || !password || !pin) {
          this.setErrorMessage('Missing required fields');
          return;
        }
        this.setLoading(true);
        const payload: ActivateUser = { email, password, pin };
        this.apiService
          .activateUser(payload, token, activateToken)
          .pipe(
            tap({
              next: (res) => {
                this.setLoading(false);
                this.setErrorMessage(null);
                this.authService.setUserInfo(res.data.userInfo);
                this.authService.setAccessToken(res.data.accessToken);
                this.checkAccessToken();
              },
              error: (error) => {
                this.setLoading(false);
                this.setErrorMessage(error.message);
              },
            })
          )
          .subscribe();
      })
    )
  );
  checkAccessToken() {
    if (this.authService.getAccessToken()) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  clearLocalStorage() {
    localStorage.clear();
  }
}
