<div class="sidebar-wrap">
  <div class="img-wrap">
    <img [src]="'./assets/images/iki-white.png'" alt="" />
  </div>
  <div>
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="example-tree"
    >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <a
          *ngIf="!node.hidden"
          [routerLink]="node.routerLink"
          class="sidebar-menu"
          (click)="activateMenu(node)"
          (keydown)="activateMenu(node)"
          [class.active]="node.isActive"
          tabindex="0"
        >
          <div style="display: flex">
            <i *ngIf="node?.icon" class="{{ node.icon }} sidebar-icon"></i>

            <app-svg-loader
              *ngIf="node?.customIcon"
              [url]="node?.customIcon"
              class="sidebar-custom-icon"
              alt="sidebar-icon"
            ></app-svg-loader>
            {{ node.label }}
          </div>
        </a>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div
          class="mat-tree-node sidebar-menu"
          matTreeNodeToggle
          [class.active]="node.isActive"
          tabindex="0"
        >
          <div style="display: flex">
            <i *ngIf="node.icon" class="{{ node.icon }} sidebar-icon"></i>

            <app-svg-loader
              *ngIf="node?.customIcon"
              [url]="node?.customIcon"
              class="sidebar-custom-icon"
              alt="sidebar-icon"
            ></app-svg-loader>
            {{ node.label }}
            <button
              class="expand-button sidebar-icon"
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.label"
            >
              <i
                [ngClass]="
                  treeControl.isExpanded(node)
                    ? 'pi pi-angle-down'
                    : 'pi pi-angle-right'
                "
              ></i>
            </button>
          </div>
        </div>
        <div
          [class.example-tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
