import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../../../environments/environment';
import { BatchDetailApiFatoryService } from '../factory/batch-detail-api-factory.service';
import { EMPTY, Observable, catchError } from 'rxjs';
import {
  IBatchDetailResponse,
  BatchDetailFilter,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/batch-detail/batch-detail.interface';

@Injectable({
  providedIn: 'root',
})
export class BatchDetailApiService extends BatchDetailApiFatoryService {
  public getApiUrl = environment.backend_portal_url + 'api/v1/disbursements';
  public getApiUrlNotUploaded =
    environment.backend_portal_url + 'api/v1/disbursements/bulk/invalid/';

  constructor(private http: HttpClient) {
    super();
  }

  getBatchDetail(
    filter: Partial<BatchDetailFilter>
  ): Observable<IBatchDetailResponse> {
    let params = new HttpParams();
    Object.keys(filter).forEach((key) => {
      const currentFilter: Record<string, any> = filter;
      if (currentFilter[key])
        params = params.set(key, currentFilter[key] as any);
    });

    return this.http.get<IBatchDetailResponse>(this.getApiUrl, { params }).pipe(
      catchError((val) => {
        return EMPTY;
      })
    );
  }

  getBatchDetailNotUploaded(id: string): Observable<IBatchDetailResponse> {
    return this.http
      .get<IBatchDetailResponse>(this.getApiUrlNotUploaded + id)
      .pipe(
        catchError((val) => {
          return EMPTY;
        })
      );
  }
}
