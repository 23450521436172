import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { BreadcrumbComponent } from 'projects/merchant-portal/src/app/components/breadcrumb/breadcrumb.component';
import { FileUploadComponent } from 'projects/merchant-portal/src/app/components/file-upload/file-upload.component';
import { NewBatchComponent } from './new-batch.component';

@NgModule({
  declarations: [NewBatchComponent],
  imports: [
    CommonModule,
    RouterModule,
    BreadcrumbComponent,
    FileUploadComponent,
    BlockLoadingComponent,
  ],
})
export class NewBatchModule {}
