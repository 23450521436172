import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchDetailComponent } from './batch-detail.component';
import { BreadcrumbComponent } from 'projects/merchant-portal/src/app/components/breadcrumb/breadcrumb.component';
import { BatchDetailFactoryService } from './factory/batch-detail-store-factory';
import { BatchDetailService } from './services/batch-detail.service';
import { BatchDetailApiService } from './services/batch-detail.api.service';
import { BatchDetailApiFatoryService } from './factory/batch-detail-api-factory.service';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [BatchDetailComponent],
  imports: [CommonModule, BreadcrumbComponent, PaginatorModule],
  providers: [
    {
      provide: BatchDetailFactoryService,
      useClass: BatchDetailService,
    },
    {
      provide: BatchDetailApiFatoryService,
      useClass: BatchDetailApiService,
    },
  ],
})
export class BatchDetailModule {}
