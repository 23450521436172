import { Injectable } from '@angular/core';
import {
  BatchDetailFilter,
  IBatchDetailResponse,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/batch-detail/batch-detail.interface';
import { Observable } from 'rxjs';

@Injectable()
export abstract class BatchDetailFactoryService {
  abstract currentBatchDetail$: Observable<IBatchDetailResponse['data']>;
  abstract currentBatchDetailNotUpload$: Observable<
    IBatchDetailResponse['data']
  >;
  abstract currentBatchDetailPagination$: Observable<
    IBatchDetailResponse['meta']
  >;
  abstract currentBatchDetailFilter$: Observable<Partial<BatchDetailFilter>>;
  abstract requestBatchDetail(param: Partial<BatchDetailFilter>): void;
  abstract requestBatchDetailNotUpload(id: string): void;
}
