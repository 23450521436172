import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { BulkTransactionItemConfirmation } from '../../../models/disbursement/bulk-transaction/bulk-transaction.interface';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-bulk-transaction-confirmation-dialog',
  templateUrl: './bulk-transaction-confirmation-dialog.component.html',
  styleUrls: ['./bulk-transaction-confirmation-dialog.component.scss'],
  providers: [DialogService],
  standalone: true,
  imports: [CommonModule],
})
export class BulkTransactionConfirmationDialogComponent {
  title = '';
  subTitle = '';
  content: BulkTransactionItemConfirmation[];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.subTitle = this.dialogConfig.data?.message;
    this.content = this.dialogConfig.data?.content;
  }

  confirm() {
    this.ref.close('success');
  }

  cancel() {
    this.ref.close();
  }
}
