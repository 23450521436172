import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PinApiService } from '../../api/pin-api.service';
import { createPinFeature } from '../store/reducers';
import { pinValidationActions } from './action';

@Injectable()
export class CreatePinEffect {
  constructor(
    private actions$: Actions,
    private pinApiService: PinApiService,
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {}

  requestPinValidation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pinValidationActions.requestPinValidation),
      withLatestFrom(
        this.store.select(createPinFeature.selectIsFromInvitation)
      ),
      switchMap(([action, isFromInvitation]) => {
        if (!isFromInvitation) {
          return this.pinApiService.createPin(action.pin).pipe(
            map(() => pinValidationActions.requestCreatePinValidationSuccess()),
            catchError((error) =>
              of(pinValidationActions.requestCreatePinValidationFailed(error))
            )
          );
        } else {
          return EMPTY; // or any other action you want to dispatch if the condition is not met
        }
      })
    );
  });

  requestCreatePinValidationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pinValidationActions.requestCreatePinValidationSuccess),
        mergeMap(() =>
          this.authService.refreshUserData().pipe(catchError(() => of(null)))
        ),
        mergeMap(() =>
          this.store.select(createPinFeature.selectIsFromInvitation).pipe(
            tap((isFromInvitation) => {
              if (!isFromInvitation) {
                this.router.navigateByUrl('/dashboard');
              }
            })
          )
        )
      ),
    { dispatch: false }
  );
}
