import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { PinApiService } from '../../api/pin-api.service';
import { ChangePinStoreFactory } from './factory/change-pin-store.factory';
import { ChangePinDialogStoreService } from './services/change-pin-dialog-store.service';

@Component({
  selector: 'app-change-pin-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CodeInputModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
  ],
  styleUrls: ['./change-pin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './change-pin-dialog.component.html',
  providers: [
    {
      provide: ChangePinStoreFactory,
      useClass: ChangePinDialogStoreService,
    },
    PinApiService,
  ],
})
export class ChangePinDialogComponent {
  forgotPin() {
    this.ref.close('forgot-pin');
  }
  pinValue = '';

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  store = inject(ChangePinStoreFactory);
  ref = inject(DynamicDialogRef);

  subTitle: Signal<string> = computed(() => {
    const map: Record<string, string> = {
      'validate-pin': 'Please enter your current PIN',
      'confirm-new-pin': 'Please re-enter your new PIN',
      'input-new-pin': 'Please create a new 6-digit number PIN',
    };
    const currentState = this.store.userStep();
    return map[currentState] ?? '';
  });

  _error$ = this.store.error$;
  error$ = computed(() => {
    const mappedError: Record<string, string> = {
      'invalid pin': 'PIN is incorrect',
    };
    return mappedError[this.store.error$() ?? '']
      ? mappedError[this.store.error$() ?? '']
      : this.store.error$();
  });
  isCurrentPin = true;
  isLoading = this.store.loading$;

  constructor() {
    effect(() => {
      const error = this.store.error$();
      if (this.store.userStep() === 'done' && !error) {
        this.ref.close('success');
      }
    });
  }

  onCodeChanged($event: string) {
    this.pinValue = $event;
  }

  async next() {
    if (this.store.userStep() !== 'confirm-new-pin') {
      this.codeInput.reset();
      this.codeInput.focusOnField(0);
    }
    this.store.next(this.pinValue);
  }
}
