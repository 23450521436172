<div  class="breadcrumb">
  <div *ngFor="let breadcrumb of breadcrumbs; let last = last; let first = first">
    <div *ngIf="!last">
      <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a> &nbsp; &#x2022;
    </div>
    <div *ngIf="last">
      {{ breadcrumb.label }}
    </div>
  </div>
</div>
