import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, of, switchMap, tap } from 'rxjs';
import {
  TransactionHistoryDetailFactoryService,
  TransactionHistoryDetailState,
} from '../factory/transaction-history-detail-store-factory';
import { TransactionHistoryDetailApiService } from '../services/transaction-history-detail.api.service';

const initialState: TransactionHistoryDetailState = {
  errorMessage: '',
  isLoading: false,
  currentTransactionHistory: null,
  downloadUrl: null,
};

@Injectable()
export class TransactionHistoryDetailStore
  extends ComponentStore<TransactionHistoryDetailState>
  implements TransactionHistoryDetailFactoryService
{
  constructor(
    private transactionHistoryDetailApiService: TransactionHistoryDetailApiService
  ) {
    super(initialState);
  }

  errorMessage$ = this.select((state) => state.errorMessage);
  isLoading$ = this.select((state) => state.isLoading);
  currentTransactionHistory$ = this.select(
    (state) => state.currentTransactionHistory
  );

  vm$ = this.select((state) => state);

  readonly requestTransactionHistory = this.effect<string>((id$) =>
    id$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap((id) =>
        this.transactionHistoryDetailApiService
          .getTransactionHistoryDetail(id)
          .pipe(
            tap({
              next: (response) => {
                this.patchState({
                  currentTransactionHistory: response.data,
                  isLoading: false,
                });
              },
              error: (error) => {
                this.patchState({
                  errorMessage: error.message,
                  isLoading: false,
                });
              },
            }),
            catchError((error) => {
              this.patchState({
                errorMessage: error.message,
                isLoading: false,
              });
              return of([]);
            })
          )
      )
    )
  );
}
