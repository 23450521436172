import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Signal, inject, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PinApiService } from '../../../api/pin-api.service';
import { CheckPinStoreFactory } from '../factory/check-pin-store.factory';

@Injectable()
export class CheckPinStoreService extends CheckPinStoreFactory {
  private _userStep = signal<'validate-pin' | 'done'>('validate-pin');
  override userStep = this._userStep;

  private _loading$ = signal(false);
  private _error$ = signal<string | null>(null);
  private _isWithoutChecking$ = signal(false);

  override loading$: Signal<boolean> = this._loading$;
  override error$: Signal<string | null> = this._error$;
  override setWithoutChecking(value: boolean): void {
    this._isWithoutChecking$.set(value);
  }

  override setError(value: string | null): void {
    this._error$.set(value);
  }

  private apiCall = inject(PinApiService);
  override async next(pin: string): Promise<void> {
    if (this._isWithoutChecking$() && this.error$() === null) {
      this._error$.set(null);
      this._userStep.update(() => 'done');
    } else {
      try {
        this._loading$.set(true);
        this._error$.set(null);

        await lastValueFrom(this.apiCall.checkPin(pin));
        this._userStep.update(() => 'done');
      } catch (error: any) {
        const httpError = error as HttpErrorResponse;
        this._error$.set(
          httpError?.error?.errors ?? httpError.message ?? 'Unknown Error'
        );
      } finally {
        this._loading$.set(false);
      }
    }
  }
}
