import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [CommonModule, OverlayPanelModule, TooltipModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @ViewChild(TooltipComponent, { static: false }) overlay:
    | TooltipComponent
    | undefined;

  @Input() tooltip: string | undefined;
  @Input() target: HTMLElement | undefined;
  @Input() tooltipStyleClass: string | undefined;
  @Input() tooltipPosition: string | undefined;
  @Input() disabled: boolean = false;
}
