import { animate, style, transition, trigger } from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReplaceStringPipe } from '@merchant-portal/app/pipe/replace-string.pipe';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject, catchError, takeUntil } from 'rxjs';
import { DisbursementService } from '../../../features/dashboard/pages/disbursement/services/disbursement.service';
import {
  ITopUpPayload,
  PaymentOption,
  TopUpResponse,
} from '../../../models/disbursement/disbursement.interface';
import { LoadingModule } from '../../loading/loading.module';
import { LoadingService } from '../../loading/services/loading.service';

@Component({
  standalone: true,
  selector: 'app-top-up-dialog',
  templateUrl: './top-up-dialog.component.html',
  styleUrls: ['./top-up-dialog.component.scss'],
  providers: [DialogService, DisbursementService],
  imports: [FormsModule, CommonModule, LoadingModule, ReplaceStringPipe],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TopUpDialogComponent implements OnDestroy {
  title = '';
  refrenceNumber = '';
  BANK_TRANSFER = 'BANK_TRANSFER';

  dialogTitle = '';

  selectedNode: PaymentOption | undefined = undefined;
  topUpResponse: TopUpResponse | undefined;

  body: PaymentOption[];
  paymentMethods = [];

  isShow = false;
  isCopied = false;
  ngUnsubscribe = new Subject<void>();

  vaSteps = [
    'Login to mobile banking/internet banking/ATM',
    'Select Bayar > Buat Pembayaran Baru > Multipayment > IKI Gateway',
    'Enter your Virtual Account Number',
    'Enter top-up amount. (Min. Rp10.000)',
    'Complete the top-up. Your balance will be updated automatically afterwards',
  ];

  manualTransferSteps = [
    `Login to <span class="capitalize">{acquirer}</span> mobile banking/internet banking/ATM`,
    `Select Transfer > Transfer to  Bank {acquirer}`,
    `Enter the account number above and the transfer amount (min. Rp10.000)`,
    `Complete the transfer and share the transfer receipt to Helpdesk <br>
   ( <a href="mailto:helpdesk@harsya.com"><b>Email</b>: helpdesk@harsya.com </a>or  
   <a target="_blank" href="https://api.whatsapp.com/send?phone=6281110608258"> <b>WA</b>: 0811-1060-8258) </a>`,
    `Your balance will be updated once validation process is done`,
  ];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private clipboard: Clipboard,
    private disbursementService: DisbursementService,
    private loadingService: LoadingService
  ) {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.content;
  }

  steps() {
    return this.isManualTransfer() ? this.manualTransferSteps : this.vaSteps;
  }

  isManualTransfer() {
    return this.selectedNode?.type === 'BANK_TRANSFER';
  }

  nodeExpand() {
    this.isShow = !this.isShow;
  }

  selectNode(event?: PaymentOption) {
    this.selectedNode = event;
    if (event?.type === this.BANK_TRANSFER) {
      this.isShow = true;
      this.refrenceNumber = event.description.split('\\n')[1];
      this.dialogTitle = event.description.split('\\n')[0];
    } else {
      this.generateVa({ paymentMethodId: event?.uuid });
    }
  }

  generateVa(paymentMethodId: ITopUpPayload) {
    this.loadingService.show();
    this.disbursementService
      .disbursementTopUp(paymentMethodId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError((error: any) => {
          this.loadingService.hide();
          throw error;
        })
      )
      .subscribe((res) => {
        if (res.data) {
          this.loadingService.hide();
          this.refrenceNumber = res.data.referenceNumber;
          this.nodeExpand();
        }
      });
  }

  copyToClipboard() {
    this.clipboard.copy(this.refrenceNumber);
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 1000);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
