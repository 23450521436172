import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-invitation-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invitation-dialog.component.html',
  styleUrls: ['./invitation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationDialogComponent implements OnInit {
  public dialogConfig: DynamicDialogConfig = inject(DynamicDialogConfig);
  private ref: DynamicDialogRef = inject(DynamicDialogRef);

  title = '';
  body = '';
  confirmText = '';
  closeText = '';
  fromData: Record<string, string> = {};
  formOrder: string[] = [];

  ngOnInit(): void {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.message;
    this.confirmText = this.dialogConfig.data?.confirmText ?? 'Confirm';
    this.closeText = this.dialogConfig.data?.closeText ?? 'Cancel';
    this.fromData = this.dialogConfig.data?.fromData ?? {};
    this.formOrder = this.dialogConfig.data?.formOrder ?? [];
  }

  close() {
    this.ref.close(false);
  }
  confirm() {
    this.ref.close(true);
  }
}
