import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import {
  ActivatedRoute,
  RouterModule,
} from '@angular/router';
import { Breadcrumb } from './interface/breadcrumb.interface';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core/core.module';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, CoreModule],
})
export class BreadcrumbComponent implements AfterViewInit {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private activatedRoute: ActivatedRoute,  private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.cdr.detectChanges();
  }

  createBreadcrumbs(
    route: ActivatedRoute,
    url = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;
  
    if (children.length === 0) {
      return breadcrumbs;
    }
  
    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
  
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
  
      const breadcrumbLabel = child.snapshot.data['breadcrumb'];
      if (breadcrumbLabel) {
        if (
          breadcrumbs.length > 0 &&
          breadcrumbs[breadcrumbs.length - 1].label === breadcrumbLabel
        ) {
          breadcrumbs[breadcrumbs.length - 1].url = url;
        } else {
          breadcrumbs.push({ label: breadcrumbLabel, url });
        }
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  
    return breadcrumbs;
  }
  
}
