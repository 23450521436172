import { Component, OnDestroy, OnInit } from '@angular/core';
import { BulkTransactionFactoryService } from './factory/bulk-transaction-store-factory';
import { PaginatorState } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { BulkTransactionFilter } from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/bulk-transaction.interface';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadingService } from '@merchant-portal/app/components/loading/services/loading.service';

@Component({
  selector: 'app-bulk-transaction',
  templateUrl: './bulk-transaction.component.html',
  styleUrls: ['./bulk-transaction.component.scss'],
})
export class BulkTransactionComponent implements OnInit, OnDestroy {
  datas: BulkTransactionFactoryService['currentBulkTransaction$'];
  totalRecord =
    this.bulkTransactionFactoryService.currentBulkTransactionPagination$;
  status = [];
  types = [];
  dateFilter: Date[] = [];
  createdDate: any;
  selectedType = '';
  selectedStatus = '';
  ngUnSubcribe = new Subject<boolean>();
  filter: Partial<BulkTransactionFilter> = {};

  constructor(
    private bulkTransactionFactoryService: BulkTransactionFactoryService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    bulkTransactionFactoryService.isLoading$
    .pipe(takeUntilDestroyed())
    .subscribe((isLoading) => {
      if (isLoading) {
        this.loadingService.show();
      } else {
        this.loadingService.hide();
      }
    });
    this.datas = this.bulkTransactionFactoryService.currentBulkTransaction$;
  }

  ngOnInit(): void {
    console.log('BulkTransactionComponent initialized');
  }

  ngOnDestroy(): void {
    this.ngUnSubcribe.complete();
  }

  onPageChange(event: PaginatorState) {
    if (event.page != undefined) {
      this.bulkTransactionFactoryService.requestBulkTransaction({
        ...this.filter,
        page: event.page + 1,
      });
    }
  }

  createBatch() {
    this.router.navigateByUrl(
      'disbursement/create-transaction/bulk-transaction/new-batch'
    );
  }

  getLastSegment(uuid: string): string {
    const segments = uuid.split('-');
    return segments[segments.length - 1];
  }
}
