<div class="success-dialog">
  <div class="wrap-content">
    <div class="title-wrap">
      <h2>{{ title }}</h2>
      <span class="subTitle" [innerHTML]="body"></span>
    </div>
    <div class="image-wrap">
      <div class="image icon progress-spinner"></div>
      <div class="overlay-text">
        <span>Upload <br />in Progress</span>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="close()" class="pg-button invert-button">
        {{ closeText }}
      </button>
      <button (click)="confirm()" class="pg-button">{{ confirmText }}</button>
    </div>
  </div>
</div>
