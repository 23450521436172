import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { myRxStompConfig } from './stomp.config';

@Injectable({
  providedIn: 'root',
})
export class StompService extends RxStomp {
  constructor() {
    super();
    this.configure(myRxStompConfig);
    this.activate();
  }

  getQueueName(userId: string): string {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    return `web.stomp.backend-portal.${userId}.${unixTimestamp}`;
  }

  getRoutingKey(userId: string): string {
    return `backend-portal.notifications.${userId}`;
  }

  watchUserNotifications(userId: string) {
    const queueName = this.getQueueName(userId);
    const routingKey = this.getRoutingKey(userId);
    return this.watch(`/exchange/backend-portal.notifications/${routingKey}`, {
      'x-queue-name': queueName,
    });
  }
}
