<div class="transaction-dialog" *ngIf="vm$ | async as vm">
  <div class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.createdAt | date : "medium"
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Last Update</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.updatedAt | date : "medium"
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Type</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.bulkId ? "BULK" : "SINGLE"
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Created By</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.createdBy
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Sender</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.senderName
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Reference ID</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.referenceId
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Remarks</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.remark
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentDisbursementHistory?.amount
              | currency : "IDR" : "symbol" : "1.0-0" : "id"
          }}</span>
        </div>
      </div>
      <hr />
      <div class="bottom-div">
        <div class="bottom-item">
          <span class="black-item" style="font-weight: 700"
            >Disbursement Detail</span
          >
        </div>
        <div class="bottom-item">
          <span>Transaction Status</span>
          <span
            [ngClass]="
              'status' + vm.currentDisbursementHistory?.transactionStatus
            "
            >{{ vm.currentDisbursementHistory?.transactionStatus }}</span
          >
        </div>
        <div
          *ngIf="vm.currentDisbursementHistory?.failedReason"
          class="bottom-item"
        >
          <span>Reason</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentDisbursementHistory?.failedReason
          }}</span>
        </div>
        <div *ngIf="vm.currentDisbursementHistory?.bulkId" class="bottom-item">
          <span>Batch ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentDisbursementHistory?.bulkId
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentDisbursementHistory?.uuid
          }}</span>
        </div>

        <div class="bottom-item">
          <span>Bank Reference</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.bankReferenceNo
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Name</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.beneficiaryBankName
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Account Number</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.beneficiaryAccountNo
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Beneficiary</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.beneficiaryAccountName
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Fee</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.fee
              | currency : "IDR" : "symbol" : "1.0-0" : "id"
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Total</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentDisbursementHistory?.totalAmount
              | currency : "IDR" : "symbol" : "1.0-0" : "id"
          }}</span>
        </div>

        <div class="bottom-item black-item" style="text-align: right"></div>
      </div>
      <hr />
      <div class="bottom-div" style="padding-bottom: 23px">
        <div class="bottom-item">
          <span class="black-item" style="font-weight: 700"
            >Approval Detail</span
          >
        </div>
        <div class="bottom-item">
          <span>Approval Status</span>
          <div>
            <span
              [ngClass]="'status' + vm.currentDisbursementHistory?.status"
              >{{ vm.currentDisbursementHistory?.status }}</span
            >
          </div>
        </div>
        <div
          *ngIf="vm.currentDisbursementHistory?.rejectReason"
          class="bottom-item"
        >
          <span>Reason</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.rejectReason
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Approval Date</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.approvedAt | date : "medium"
          }}</span>
        </div>
        <div class="bottom-item">
          <span>Approved By</span>
          <span class="black-item">{{
            vm.currentDisbursementHistory?.approvedBy
          }}</span>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
      <div class="tooltip-wrapper">
        <button
          [disabled]="
            vm.currentDisbursementHistory?.transactionStatus !== 'SUCCESS'
          "
          (click)="download()"
          style="width: 200px"
          class="pg-button"
          (mouseover)="
            showTooltip =
              vm.currentDisbursementHistory?.transactionStatus !== 'SUCCESS'
          "
          (mouseout)="showTooltip = false"
        >
          Download Receipt
        </button>
        <div *ngIf="showTooltip" class="tooltip">
          Receipt cannot be downloaded because the disbursement status has not
          been successful.
        </div>
      </div>
    </div>
  </div>
  <app-block-loading [show]="vm.isLoading"></app-block-loading>
</div>
