import { CommonModule } from '@angular/common';
import {
  Component,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PinApiService } from '../../api/pin-api.service';
import { CheckPinStoreFactory } from './factory/check-pin-store.factory';
import { CheckPinStoreService } from './services/check-pin-store.service';

@Component({
  standalone: true,
  selector: 'app-check-pin-dialog',
  templateUrl: './check-pin-dialog.component.html',
  styleUrls: ['./check-pin-dialog.component.scss'],
  providers: [
    PinApiService,
    {
      provide: CheckPinStoreFactory,
      useClass: CheckPinStoreService,
    },
  ],
  imports: [
    CommonModule,
    CodeInputModule,
    CardModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
  ],
})
export class CheckPinDialogComponent {
  pinValue = '';

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  store = inject(CheckPinStoreFactory);
  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig);

  subTitle: Signal<string> = computed(() => {
    const map: Record<string, string> = {
      'validate-pin': 'Please enter your PIN to continue',
      'confirm-new-pin': 'Please re-enter your new PIN',
      'input-new-pin': 'Please create a new 6-digit number PIN',
    };
    const currentState = this.store.userStep();
    return map[currentState] ?? '';
  });

  _error$ = this.store.error$;
  error$ = computed(() => {
    const mappedError: Record<string, string> = {
      'invalid pin': 'PIN is incorrect',
    };
    return mappedError[this.store.error$() ?? '']
      ? mappedError[this.store.error$() ?? '']
      : this.store.error$();
  });
  isCurrentPin = true;
  isLoading = this.store.loading$;

  constructor() {
    const isWithoutChecking = this.config?.data?.isWithoutChecking ?? false;
    this.store.setWithoutChecking(isWithoutChecking);
    const error = this.config?.data?.error ?? false;
    this.store.setError(error);

    effect(() => {
      const error = this.store.error$();
      if (this.store.userStep() === 'done' && !error) {
        this.ref.close(this.pinValue);
      }
    });
  }

  onCodeChanged($event: string) {
    this.pinValue = $event;
  }

  async next() {
    await this.store.next(this.pinValue);
  }
}
