import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { hasPermissionGuardGuard } from '@merchant-portal/app/core/user-access/has-permission-guard.guard';
import { CreateTransactionComponent } from './create-transaction.component';
import { SingleTransactionComponent } from './single-transaction/single-transaction.component';

const routes: Routes = [
  {
    path: '',
    component: CreateTransactionComponent,
  },
  {
    path: 'single-transaction',
    component: SingleTransactionComponent,
    canActivate: [hasPermissionGuardGuard],
    data: {
      breadcrumb: 'Single Transaction',
      userAccess: 'disbursement.disbursement-create.create',
    },
  },
  {
    path: 'bulk-transaction',
    canActivate: [hasPermissionGuardGuard],

    loadChildren: () =>
      import('./bulk-transaction/bulk-transaction.routing.module').then(
        (m) => m.BulkTransactionRoutingModule
      ),
    data: {
      breadcrumb: 'Bulk Transaction',
      userAccess: 'disbursement.disbursement-create.create',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreateTransactionRoutingModule {}
