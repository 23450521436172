import {
  ITeamMemberData,
  Meta,
  TeamMemberFilter,
} from '@merchant-portal/app/models/setting/team-member.interface';
import { IRole } from '@merchant-portal/app/models/user-role/role.interface';
import { createFeature, createReducer, on } from '@ngrx/store';
import { userListAction } from './action';

interface State {
  requestPermissionLoading: boolean;
  requestUserListLoading: boolean;
  isTeamMemberDialogClose: boolean;
  teamMemberList: ITeamMemberData[];
  meta: Meta | undefined;
  detailUser: ITeamMemberData | undefined;
  filter: Partial<TeamMemberFilter> | undefined;
  allRole: IRole[];
}

const initialState: State = {
  requestPermissionLoading: false,
  requestUserListLoading: false,
  isTeamMemberDialogClose: false,
  teamMemberList: [],
  meta: undefined,
  detailUser: undefined,
  filter: undefined,
  allRole: [],
};

const reducer = createReducer(
  initialState,
  on(
    userListAction.requestAllPermissions,
    (state: State): State => ({
      ...state,
      requestUserListLoading: true,
    })
  ),
  on(
    userListAction.requestAllUsers,
    (state: State, { filter }): State => ({
      ...state,
      filter: filter,
      requestUserListLoading: true,
    })
  ),
  on(
    userListAction.requestAllUsersSuccess,
    (state: State, { users, meta }): State => ({
      ...state,
      requestUserListLoading: false,
      teamMemberList: users,
      meta: meta,
    })
  ),
  on(
    userListAction.requestAllUsersFailed,
    (state: State): State => ({
      ...state,
      requestUserListLoading: false,
    })
  ),
  on(
    userListAction.requestDetailUser,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: false,
      requestUserListLoading: true,
    })
  ),
  on(
    userListAction.requestDetailUserSuccess,
    (state: State, { users }): State => ({
      ...state,
      isTeamMemberDialogClose: false,
      detailUser: users,
      requestUserListLoading: false,
    })
  ),
  on(
    userListAction.showAddUserDialog,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: false,
    })
  ),
  on(
    userListAction.showUpdateUserDialog,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: false,
    })
  ),
  on(
    userListAction.requestCreateTeamMemberSuccess,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: true,
    })
  ),
  on(
    userListAction.requestCreateTeamMemberFailed,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: false,
    })
  ),
  on(
    userListAction.requestEditTeamMemberSuccess,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: true,
    })
  ),
  on(
    userListAction.requestEditTeamMemberFailed,
    (state: State): State => ({
      ...state,
      isTeamMemberDialogClose: false,
    })
  ),
  on(
    userListAction.requestDeactiveUser,
    (state: State): State => ({
      ...state,
      requestUserListLoading: true,
    })
  ),
  on(
    userListAction.requestActiveUser,
    (state: State): State => ({
      ...state,
      requestUserListLoading: true,
    })
  ),
  on(
    userListAction.requestResendInvitationUser,
    (state: State): State => ({
      ...state,
      requestUserListLoading: false,
    })
  ),
  on(
    userListAction.requestAllPermissions,
    (state: State): State => ({ ...state, requestUserListLoading: true })
  ),
  on(
    userListAction.requestAllPermissionsSuccess,
    (state: State, { permissions }): State => ({
      ...state,
      allRole: permissions,
      requestUserListLoading: false,
    })
  ),
  on(
    userListAction.requestAllPermissionsFailed,
    (state: State): State => ({
      ...state,
      requestUserListLoading: false,
    })
  )
);

export const userlistFeature = createFeature({
  name: 'userList',
  reducer,
});
