import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserAccessDirective } from '@merchant-portal/app/core/user-access/user-access-directive';
import { viewRoleAction } from '@merchant-portal/app/features/dashboard/pages/setting/roles/view-roles/store/action';
import { groupByPermissionGroup } from '@merchant-portal/app/features/dashboard/pages/setting/roles/view-roles/store/view-role.selector';
import { DashboardAction } from '@merchant-portal/app/features/dashboard/store/dashboard.action';
import { Store } from '@ngrx/store';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-show-role-permission-dialog',
  standalone: true,
  imports: [CommonModule, CheckboxModule, FormsModule, UserAccessDirective],
  templateUrl: './show-role-permission-dialog.component.html',
  styleUrls: ['./show-role-permission-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowRolePermissionDialogComponent implements OnInit {
  public dialogConfig: DynamicDialogConfig = inject(DynamicDialogConfig);
  private store = inject(Store);
  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  roleName = '';
  isDefaultRole = false;
  uuid = '';
  groupMap = this.store.select(groupByPermissionGroup);
  permissionMap: Observable<Record<string, string[]>> = this.groupMap.pipe(
    map((value) => value.map),
  );
  menuOrder: Observable<string[] | undefined> = this.groupMap.pipe(
    map((value) => value.menuOrder),
  );
  checked = true;

  ngOnInit(): void {
    this.roleName = this.dialogConfig.data.roleName;
    this.isDefaultRole = this.dialogConfig.data.isDefaultRole;
    this.uuid = this.dialogConfig.data.uuid;
    this.store.dispatch(DashboardAction.requestUserMenus({ id: this.uuid }));
    this.store.dispatch(
      viewRoleAction.requestShowRoleDetail({
        id: this.dialogConfig.data.uuid,
      }),
    );
  }

  onClose() {
    this.ref.close();
  }
}
