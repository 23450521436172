import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { createPinFeature } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { CreatePinEffect } from './store/effect';
import { PinApiService } from '../api/pin-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(createPinFeature.name, createPinFeature.reducer),
    EffectsModule.forFeature([CreatePinEffect]),
  ],
  exports: [],
  providers: [PinApiService,],
})
export class CreatePinModule {}
