export const userAccess: Record<string, Record<string, number>> = {
  admin: {
    edit_members: 1,
    add_members: 1,
    dashboard_view: 1,
    disbursement_view: 1,
    transaction_view: 1,
  },
  Developer: {
    developer_setting: 1,
    edit_members: 1,
    add_members: 1,
    dashboard_view: 1,
    disbursement_view: 1,
    transaction_view: 1,
    create_transaction: 1,
    approval_view: 1,
    beneficiary_view: 1,
  },
};
