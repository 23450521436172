<div class="wrap-container">
  <h3>Create Transaction</h3>
  <div class="flex-container">
    <div class="flex-1">
      <div [userAccess]="'disbursement.disbursement-create.create'" [style]="" class="item"
        (click)="navigateToSingleTransaction()" tabindex="0" (keydown.enter)="navigateToSingleTransaction()">
        <i class="header-content pi pi-send"></i>
        <div class="main-content">
          <span>Single Transaction <i class="pi pi-arrow-right arrow-icon"></i></span>
        </div>
        <div class="footer-content">
          <span>Send money to a single bank account.</span>
        </div>
      </div>
    </div>

    <div class="flex-1">
      <div class="item" (click)="navigateToBulkTransaction()" tabindex="0" (keydown.enter)="navigateToBulkTransaction()"
        [userAccess]="'disbursement.disbursement-create.create'">
        <div class="header-content">
          <img [src]="'../../../../../../assets/icon/upload-file.svg'" alt="upload" />
        </div>
        <div class="main-content">
          <span>Bulk Transaction <i class="pi pi-arrow-right arrow-icon"></i></span>
        </div>
        <div class="footer-content">
          <span>Send money to multiple bank accounts by uploading .xls file.</span>
        </div>
      </div>
    </div>

  </div>
</div>