import { Component, OnDestroy, OnInit } from '@angular/core';
import { BatchDetailFactoryService } from './factory/batch-detail-store-factory';
import { PaginatorState } from 'primeng/paginator';
import {
  Subject,
} from 'rxjs';
import {
  BatchDetailFilter,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/batch-detail/batch-detail.interface';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-batch-detail',
  templateUrl: './batch-detail.component.html',
  styleUrls: ['./batch-detail.component.scss'],
})
export class BatchDetailComponent implements OnInit, OnDestroy {
  datas: BatchDetailFactoryService['currentBatchDetail$'];
  datasNotUploaded: BatchDetailFactoryService['currentBatchDetailNotUpload$'];
  totalRecord = this.batchDetailFactoryService.currentBatchDetailPagination$;
  ngUnSubcribe = new Subject<boolean>();
  filter: Partial<BatchDetailFilter> = {};
  isNotUploaded = false;

  constructor(
    private batchDetailFactoryService: BatchDetailFactoryService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe(params => {
      const bulkId = params.get('id')!;
      this.filter = { bulkId: bulkId };
    });
    this.onFilterChange();
    this.datas = this.batchDetailFactoryService.currentBatchDetail$;
    this.datasNotUploaded = this.batchDetailFactoryService.currentBatchDetailNotUpload$;
  }

  ngOnInit(): void {
    console.log('BatchDetailComponent initialized');
  }

  ngOnDestroy(): void {
    this.ngUnSubcribe.complete();
  }

  onPageChange(event: PaginatorState) {
    if (event.page) {
      this.batchDetailFactoryService.requestBatchDetail({
        ...this.filter,
        page: event.page + 1,
      });
    }
  }

  createBatch() {
    this.router.navigateByUrl(
      'disbursement/create-transaction/batch-detail/new-batch'
    );
  }

  onClose() {
    this.location.back();
  }

  onFilterChange() {
    this.batchDetailFactoryService.requestBatchDetail({
      ...this.filter,
      page: 0,
    });
  }

  onFilterChangeNotUploaded() {
    this.batchDetailFactoryService.requestBatchDetail({
      ...this.filter,
    });
  }

  checkStatus() {
    if (this.isNotUploaded) {
      this.onFilterChangeNotUploaded()
    } else {
      this.onFilterChange()
    }
  }
}
