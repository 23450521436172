<body>
  <img class="img-logo" [src]="'../../../assets/images/iki.png'" alt="" />
  <div class="session">
    <form class="account-blocked-form" autocomplete="off">
      <h4>Account Blocked</h4>
      <span>
        Please request for unblock by sending an email to:
        <br>
        helpdesk@harsya.com</span
      >
      <button [routerLink]="['/login']" type="submit">Close</button>
    </form>
  </div>
</body>
