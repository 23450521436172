import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Signal, inject, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PinApiService } from '../../../api/pin-api.service';
import { ChangePinStoreFactory } from '../factory/change-pin-store.factory';

@Injectable()
export class ChangePinDialogStoreService extends ChangePinStoreFactory {
  private _userStep = signal<
    'validate-pin' | 'input-new-pin' | 'confirm-new-pin' | 'done'
  >('validate-pin');
  override userStep = this._userStep;

  private userPin = '';
  private newPin = '';
  private reEnterNewPin = '';

  private _loading$ = signal(false);
  private _error$ = signal<string | null>(null);

  override loading$: Signal<boolean> = this._loading$;
  override error$: Signal<string | null> = this._error$;
  private apiCall = inject(PinApiService);
  async submitPin(oldPin: string, newPin: string, reEnterNewPin: string) {
    if (newPin !== reEnterNewPin) {
      this._error$.set('PIN does not match');
      return null;
    }
    try {
      this._loading$.set(true);
      this._error$.set(null);

      const { data } = await lastValueFrom(
        this.apiCall.changePin({
          pin: oldPin,
          newPin,
        })
      );
      return data;
    } catch (error) {
      const httpError: HttpErrorResponse = error as any;
      this._error$.set(
        httpError?.error?.errors ?? httpError.message ?? 'Unkown Error'
      );
      return null;
    } finally {
      this._loading$.set(false);
    }
  }

  async checkPin(pin: string) {
    try {
      console.log('masuk');

      this._loading$.set(true);
      this._error$.set(null);

      const data = await lastValueFrom(this.apiCall.checkPin(pin));
      return data;
    } catch (error) {
      this._error$.set('Incorrect PIN');
      return null;
    } finally {
      this._loading$.set(false);
    }
  }

  override async next(pin: string): Promise<void> {
    switch (this.userStep()) {
      case 'validate-pin':
        {
          this.userPin = pin;
          const response = await this.checkPin(this.userPin);
          if (response) {
            this._userStep.set('input-new-pin');
          }
        }
        break;
      case 'input-new-pin':
        this.newPin = pin;
        this._userStep.set('confirm-new-pin');
        break;
      case 'confirm-new-pin':
        this.reEnterNewPin = pin;
        if (this.newPin !== this.reEnterNewPin) {
          this._error$.set('PIN does not match');
          break;
        } else {
          const response = await this.submitPin(
            this.userPin,
            this.newPin,
            this.reEnterNewPin
          );
          if (response) {
            this._userStep.set('done');
          }
        }
        break;
      default:
        break;
    }
  }
}
