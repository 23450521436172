import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface LoginParams {
  email: string;
  password: string;
  isRemember: boolean
}

export interface LoginState {
  errorMessage: string;
  isOtpRequired: boolean;
  isCountDownStarted: boolean;
  isDeviceRecognized: boolean,
}
@Injectable()
export abstract class LoginStoreFactory {
  abstract vm$: Observable<LoginState>;
  abstract login(params: LoginParams): void;
  abstract disableOtpRequire(): void;
  abstract checkAccessToken(): void;
  abstract checkStoredFingerprint(email: string): void;
  abstract getFingerPrintId(email: string): string
}
