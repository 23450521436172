import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { IMenuResponse } from '@merchant-portal/app/models/menu/menu.interface';
import { environment } from '@merchant-portal/environments/environment';
import { AuthService } from '../../auth.service';
import { userAccess } from '../user-access';

@Injectable()
export class UserAccessService {
  private http = inject(HttpClient);
  constructor(private user: AuthService) {}

  getUserMenu(roleId: string) {
    return this.http.get<IMenuResponse>(
      environment.backend_portal_url + 'api/v1/menus/role/' + roleId
    );
  }

  getUserAccess(role: string) {
    return userAccess[role];
  }

  isHavingAccess(access: string) {
    const role = this.user.getUserInfo()?.role;
    return role && this.getUserAccess(role)
      ? this.getUserAccess(role)[access]
      : true;
  }

  isHavingRoleAndAccess(access: string) {
    const role = this.user.getUserInfo()?.role ?? '';
    return role && this.getUserAccess(role)
      ? this.getUserAccess(role)[access]
      : false;
  }
}
