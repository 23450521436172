import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './check-password-dialog.component.html',
  styleUrls: ['./check-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService],
})
export class CheckPasswordDialogComponent {
  private fb: FormBuilder = inject(FormBuilder);
  private authService = inject(AuthService);
  passwordForm = this.fb.group({
    password: ['', [Validators.required]],
  });
  private currentPassword = '';

  errorMessage = signal('');

  errorMapper: Record<string, string> = {
    'ERROR_REQUEST | ERROR_UNAUTHORIZED | invalid password':
      'Password is incorrect.',
  };

  error = computed(() => {
    return this.errorMapper[this.errorMessage()]
      ? this.errorMapper[this.errorMessage()]
      : this.errorMessage();
  });

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.currentPassword = this.dialogConfig.data?.currentPassword;
  }

  inputType = {
    password: 'password',
  };

  toggleInputType(type: keyof typeof this.inputType) {
    this.inputType[type] =
      this.inputType[type] === 'password' ? 'text' : 'password';
  }
  onSubmitNewPassword() {
    this.errorMessage.set('');

    this.authService
      .checkPassword({
        password: this.passwordForm.get('password')?.value ?? '',
      })
      .pipe(
        catchError(({ error }: HttpErrorResponse) => {
          this.errorMessage.set(error.message);
          return of('');
        })
      )
      .subscribe((res) => {
        if (res) {
          this.ref.close(this.passwordForm.get('password')?.value);
        }
      });
  }
}
