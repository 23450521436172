// global-spinner.component.ts

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-global-spinner',
  template: `
    <div *ngIf="isLoading" class="overlay">
      <div class="overlay-content">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </div>
  `,
  styles: [`
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .overlay-content {
      text-align: center;
    }
  `],
})
export class LoadingComponent implements OnDestroy {
  isLoading = false;
  private subscription: Subscription;

  constructor(private loadingService: LoadingService) {
    this.subscription = this.loadingService.isLoading$.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
