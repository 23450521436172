import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { IMenuResponse } from '@merchant-portal/app/models/menu/menu.interface';
import { IRolePermissionData } from '@merchant-portal/app/models/user-role/role.interface';
import { createSelector } from '@ngrx/store';
import { viewRolesFeature } from './reducer';

export const mapUserRolePermission = (
  menu: IMenuResponse | null,
  data: IRolePermissionData[]
): { menuOrder: string[] | undefined; map: Record<string, string[]> } => {
  const map: Record<string, string[]> = {};
  const permisisonMap: Record<string, string> = {};

  // save record between permissionslug and name
  data.forEach((item) => {
    permisisonMap[item.slug] = item.name;
  });

  data.forEach((item) => {
    if (!map[item.group]) {
      map[item.group] = [];
    }
    map[item.group].push(permisisonMap[item.slug]);
  });

  const menuOrder = new Set(data?.map((menu) => menu.group));

  return { menuOrder: [...menuOrder], map };
};
export const groupByPermissionGroup = createSelector(
  dashboardFeature.selectMenu,
  viewRolesFeature.selectRolePermissionDetail,
  mapUserRolePermission
);
