<div class="wrap-container">
  <div class="page-subtitle">
    <span class="pending-balance" style="width: 50%;">
      Balance<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'" tooltipPosition="bottom"
        [pTooltip]="tooltipContentBalance" url="helper.svg"></app-svg-loader>
      <ng-template #tooltipContentBalance>
        <div>
          <span class="tooltip-text"> The number of total balance.</span>
        </div>
      </ng-template>
    </span>
    <span class="pending-balance">
      Waiting for Top Up<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'"
        tooltipPosition="bottom" [pTooltip]="tooltipContentWaiting" url="helper.svg"></app-svg-loader>
      <ng-template #tooltipContentWaiting>
        <div>
          <span class="tooltip-text"> The number of disbursements that could not be processed due to insufficient funds
            today.</span>
        </div>
      </ng-template>
    </span>
  </div>
  <div class="item-top balance flex-grow-2">
    <div class="pending-balance">
      <span class="header-content"><i style="margin-top: -4px;" class="pi pi-wallet"></i> Available Balance
      </span><app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'" tooltipPosition="bottom"
        [pTooltip]="tooltipContentAvailable" url="helper.svg"></app-svg-loader>
    </div>

    <ng-template #tooltipContentAvailable>
      <div>
        <span class="tooltip-text"> The number of total available balance.</span>
      </div>
    </ng-template>

    <div class="header-flex">
      <div class="main-content top-content">
        <div>
          {{
          disbursementSummary?.availableBalance?.value
          | currency : "IDR" : "symbol" : "1.0-0" : "id"
          }}
        </div>
      </div>
      <button [userAccess]="'disbursement.top-up.create'" pButton [disabled]="!paymentMethodList"
        (click)="showTopUpDialog()" class="pg-button">
        Top Up
      </button>
    </div>


    <hr width="100%" style="border: 1px solid #E4E4E4;" />
    <div class="header-flex">
      <div class="pending-balance">
        <span class="header-content"> Pending Balance</span><app-svg-loader class="svg-icon"
          [tooltipStyleClass]="'tooltipApproval'" tooltipPosition="bottom" [pTooltip]="tooltipContentPending"
          url="helper.svg"></app-svg-loader>
      </div>

      <ng-template #tooltipContentPending>
        <div>
          <span class="tooltip-text"> Balance used by transactions that are still being processed</span>
        </div>
      </ng-template>
      <span class="header-content"> {{disbursementSummary?.pendingBalance?.value | currency : "IDR" : "symbol" : "1.0-0"
        : "id"}}</span>
    </div>

  </div>

  <app-disbursement-info-card permission="disbursement.disbursement-approval.view" class="flex-grow-1"
    title="Single Transaction" [href]="topUpSingleLink"
    [count]="disbursementSummary?.waitingForTopUpTodaySingleTransaction?.count?.toString()" [ammount]="
    disbursementSummary?.waitingForTopUpTodaySingleTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="waiting">
  </app-disbursement-info-card>
  <app-disbursement-info-card permission="disbursement.disbursement-approval.view" class="flex-grow-1"
    title="Bulk Transaction" [href]="topUpBulkLink" [ammount]="
    disbursementSummary?.waitingForTopUpTodayBulkTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " [count]="disbursementSummary?.waitingForTopUpTodayBulkTransaction?.count?.toString()" status="waiting">
  </app-disbursement-info-card>

  <p class="page-subtitle">Approval<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'"
      tooltipPosition="bottom" [pTooltip]="tooltipContentApproval" url="helper.svg"></app-svg-loader></p>
  <ng-template #tooltipContentApproval>
    <div>
      <span class="tooltip-text"> The number of disbursements that are waiting for approval and have been approved or
        rejected today.</span>
    </div>
  </ng-template>
  <app-disbursement-info-card permission="disbursement.disbursement-history.view" count="0"
    title="Waiting Single Transaction" [href]="totalWaitingForApprovalSingleTodayLink" [ammount]="
      disbursementSummary?.waitingTodaySingleTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " [count]="disbursementSummary?.waitingTodaySingleTransaction?.count?.toString()" status="waiting">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" permission="disbursement.disbursement-history.view"
    title="Waiting Bulk Transaction" [count]="disbursementSummary?.waitingTodayBulkTransaction?.count?.toString()"
    [href]="totalWaitingForApprovalBulkTodayLink" [ammount]="
      disbursementSummary?.waitingTodayBulkTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="waiting">
  </app-disbursement-info-card>
  <app-disbursement-info-card class="flex-grow-1" permission="disbursement.disbursement-history.view" title="Approved"
    [count]="disbursementSummary?.approvedTodayTransaction?.count?.toString()" [href]="totalApprovedTodayLink"
    [ammount]="
      disbursementSummary?.approvedTodayTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    ">
  </app-disbursement-info-card>
  <app-disbursement-info-card title="Rejected" permission="disbursement.disbursement-history.view"
    [href]="totalRejectedTodayLink" [count]="disbursementSummary?.rejectedTodayTransaction?.count?.toString()"
    [ammount]="
    disbursementSummary?.rejectedTodayTransaction?.sum?.value?.toString()
      | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
  ">
  </app-disbursement-info-card>

  <p class="page-subtitle">Disbursement<app-svg-loader class="svg-icon" [tooltipStyleClass]="'tooltipApproval'"
      tooltipPosition="bottom" [pTooltip]="tooltipContentDisbursement" url="helper.svg"></app-svg-loader></p>
  <ng-template #tooltipContentDisbursement>
    <div>
      <span class="tooltip-text"> The number of approved disbursements that are pending, successful, or failed
        today.</span>
    </div>
  </ng-template>
  <app-disbursement-info-card title="Pending" permission="disbursement.disbursement-history.view"
    [href]="totalPendingTodayLink" [count]="disbursementSummary?.pendingTodayTransaction?.count?.toString()" [ammount]="
      disbursementSummary?.pendingTodayTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    ">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" title="Success" permission="disbursement.disbursement-history.view"
    [href]="totalSuccessTodayLink" [count]="disbursementSummary?.successTodayTransaction?.count?.toString()" [ammount]="
      disbursementSummary?.successTodayTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="success">
  </app-disbursement-info-card>

  <app-disbursement-info-card class="flex-grow-1" title="Failed" permission="disbursement.disbursement-history.view"
    [href]="totalFailedTodayLink" [count]="disbursementSummary?.failedTodayTransaction?.count?.toString()" [ammount]="
      disbursementSummary?.failedTodayTransaction?.sum?.value?.toString()
        | currency : 'IDR' : 'symbol' : '1.0-0' : 'id'
    " status="failed">
  </app-disbursement-info-card>
</div>