<div class="success-dialog">
  <div class="content-wrap">
    <div class="title-wrap">
      <h2>{{ title }}</h2>
      <pre>{{ body }}</pre>
    </div>
    <div *ngIf="showDownload" class="template-container">
      <div class="template-content">
        <div>
          <img [src]="'../../../../../assets/icon/excel-icon.svg'" alt="" />
          <span class="title">{{ failedType }}</span>
        </div>

        <span class="sub-title" style="color: #929292">{{
          this.failedTypeSubtitle
            | defaultValue
              : "Download the invalid transactions if you wish to revise"
        }}</span>
      </div>
      <div>
        <button
          pButton
          class="pg-button invert-button"
          type="button"
          (click)="downloadTemplate()"
        >
          Download
        </button>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="close()" class="pg-button">Close</button>
    </div>
  </div>
</div>
