import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { EnvFlagService } from '../services/env-flag.service';

export const envFlagGuard: CanActivateFn = (route) => {
  const flagService = inject(EnvFlagService);
  const router = inject(Router);
  const requiredFlag = route.data['flag'];

  if (requiredFlag && !flagService.getFlag(requiredFlag)) {
    return router.navigateByUrl('/');
  }

  return true;
};
