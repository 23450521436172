import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IBeneficiaryListResponse, BeneficiaryListFilter } from 'projects/merchant-portal/src/app/models/disbursement/beneficiary-list/beneficiary-list.interface';

@Injectable()
export abstract class BeneficiaryListFactoryService {
  abstract currentBeneficiaryList$: Observable<IBeneficiaryListResponse['data']>;
  abstract currentBeneficiaryListPagination$: Observable<IBeneficiaryListResponse['meta']>;
  abstract currentBeneficiaryListFilter$: Observable<Partial<BeneficiaryListFilter>>;
  abstract requestBeneficiaryList(param: Partial<BeneficiaryListFilter>): void;
}
