import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { isDevMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

async function prepareApp() {
  if (isDevMode() && environment.enableMock) {
    const { worker } = await import('./mock/browser');
    return worker.start();
  }

  return Promise.resolve();
}

prepareApp().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
