import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { Observable } from 'rxjs';
import { passwordValidator } from '../guard/password.guard.validator';
import { OtpComponent } from '../otp/otp.component';
import { CreatePinComponent } from '../pin/create-pin/create-pin.component';
import { CreatePinModule } from '../pin/create-pin/create-pin.module';
import { CreatePinStoreFactory } from '../pin/create-pin/factory/create-pin-store';
import { CreatePinStoreService } from '../pin/create-pin/services/create-pin-store.service';
import { LoginInvitationStore } from './login-invitation.store';

@Component({
  selector: 'app-login-invitation',
  templateUrl: './login-invitation.component.html',
  styleUrls: ['./login-invitation.component.scss'],
  providers: [
    LoginInvitationStore,
    {
      provide: CreatePinStoreFactory,
      useClass: CreatePinStoreService,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
    CreatePinModule,
    CreatePinComponent,
    OtpComponent,
  ],
})
export class LoginInvitationComponent implements OnInit {
  step$: Observable<'password' | 'pin' | 'otp'> =
    this.loginInvitationStore.step$;
  passwordForm: FormGroup;
  startOtp$: Observable<boolean> = this.loginInvitationStore.startOtp$;
  inputType = {
    password: 'password',
    reEnterPassword: 'password',
  };

  constructor(
    private fb: FormBuilder,
    private loginInvitationStore: LoginInvitationStore,
    private route: ActivatedRoute
  ) {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, passwordValidator()]],
      reEnterPassword: ['', [Validators.required, passwordValidator()]],
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      const token = params.get('token');
      if (token) {
        this.loginInvitationStore.setToken(token);
        this.loginInvitationStore.verifyLinkInvitation({ token });
      } else {
        this.loginInvitationStore.checkAccessToken();
        this.loginInvitationStore.clearLocalStorage();
      }
    });
  }

  onNextPassword() {
    if (this.passwordForm.valid) {
      this.loginInvitationStore.setPassword(this.passwordForm.value.password);
      this.loginInvitationStore.setStep('pin');
      this.loginInvitationStore.setStartOtp(true);
    }
  }

  activateUser() {
    this.loginInvitationStore.pin$.subscribe(() => {
      this.loginInvitationStore.activateUser();
    });
  }

  onPinCreated(event: string) {
    this.loginInvitationStore.setPin(event);
    this.loginInvitationStore.setStep('otp');
  }

  onOtpComplete(event: boolean) {
    if (event) {
      this.activateUser();
    }
  }

  toggleInputType(field: 'password' | 'reEnterPassword') {
    this.inputType[field] =
      this.inputType[field] === 'password' ? 'text' : 'password';
  }

  @HostListener('window:beforeunload', ['$event'])
  confirmNavigation(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = true; // This line is necessary for some browsers
    const confirmationMessage =
      'Are you sure you want to leave this page? Your data may be lost.';
    event.returnValue = confirmationMessage; // For older browsers
    return confirmationMessage;
  }
}
