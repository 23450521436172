<div>
  <div class="p-card-title">Change PIN</div>
  <div class="p-card-subtitle">
    {{ subTitle() }}
  </div>
</div>

<div>
  <code-input
    #codeInput
    class="pin-input invalid"
    [isCodeHidden]="true"
    [codeLength]="6"
    [isCharsCode]="false"
    (codeChanged)="onCodeChanged($event)"
    [ngClass]="{
      invalid: error$()
    }"
  >
  </code-input>
  <p class="warning-message" *ngIf="error$()">
    <i class="pi pi-exclamation-triangle"></i> {{ error$() }}
  </p>
</div>

<div class="button-group">
  <button class="pg-button" (click)="next()">Next</button>
  <button (click)="forgotPin()" class="forgot-pin-button">Forgot PIN?</button>
</div>

<app-block-loading [show]="isLoading()"></app-block-loading>
