<h2 id="page-title">Bulk Transaction</h2>
<app-breadcrumb></app-breadcrumb>
<div class="wrap-button">
  <input
    [(ngModel)]="isNotUploaded"
    (ngModelChange)="checkStatus()"
    type="checkbox"
    id="toggle"
    class="toggleCheckbox"
  />
  <label for="toggle" class="toggleContainer">
    <div><i class="pi pi-check-circle"></i>Uploaded</div>
    <div><i class="pi pi-times-circle"></i>Not Uploaded</div>
  </label>
  <button
    pButton
    class="pg-button invert-button"
    type="button"
    (click)="createBatch()"
  >
    <img
      class="custom-icon"
      [src]="'../../../../../../assets/icon/excel-icon.svg'"
      alt="upload"
    />
    Download
  </button>
</div>

<div class="table-container">
  <table *ngIf="!isNotUploaded" aria-describedby="page-title">
    <thead>
      <tr>
        <th>Dest. Bank</th>
        <th>Dest. Account</th>
        <th>Beneficiary</th>
        <th>Amount</th>
        <th>Ref. ID</th>
        <th>Remarks</th>
        <th>Trx. Status</th>
        <th>Reject Reason</th>
        <th>Failed Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of datas | async">
        <td>{{ data.beneficiaryBankName }}</td>
        <td>{{ data.beneficiaryAccountNo }}</td>
        <td>{{ data.beneficiaryAccountName }}</td>
        <td>{{ data.amount | number : "1.0-0" : "id" }}</td>
        <td>{{ data.referenceId }}</td>
        <td>{{ data.remark }}</td>
        <td>{{ data.status }}</td>
        <td>{{ data.reasonDescription }}</td>
        <td>-</td>
      </tr>
    </tbody>
  </table>
  <table *ngIf="isNotUploaded" aria-describedby="page-title">
    <thead>
      <tr>
        <th>Dest. Bank</th>
        <th>Dest. Account</th>
        <th>Beneficiary</th>
        <th>Amount</th>
        <th>Ref. ID</th>
        <th>Remarks</th>
        <th>Result</th>
        <th>Error</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of datasNotUploaded | async">
        <td>{{ data.beneficiaryBankName }}</td>
        <td>{{ data.beneficiaryAccountNo }}</td>
        <td>{{ data.beneficiaryAccountName }}</td>
        <td>{{ data.amount | number : "1.0-0" : "id" }}</td>
        <td>{{ data.referenceId }}</td>
        <td>{{ data.remark }}</td>
        <td>{{ data.status }}</td>
        <td>{{ data.reasonDescription }}</td>
        <td>-</td>
      </tr>
    </tbody>
  </table>
</div>

<p-paginator
  *ngIf="totalRecord | async as pagination"
  [totalRecords]="pagination.totalItems"
  [rows]="pagination.perPage"
  (onPageChange)="onPageChange($event)"
>
</p-paginator>
<button pButton class="pg-button" type="button" (click)="onClose()">
  Close
</button>
