import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  inject,
} from '@angular/core';
import { TooltipDirectiveDirective } from '@merchant-portal/app/components/tooltip/directive/tooltip-directive.directive';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';

@Directive({
  selector: '[userAccess]',
  standalone: true,
  hostDirectives: [
    {
      directive: TooltipDirectiveDirective,
    },
  ],
})
export class UserAccessDirective
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input() userAccess = '';

  userAccess$ = new BehaviorSubject(this.userAccess);

  private store = inject(Store);
  private tooltipDirective = inject(TooltipDirectiveDirective);
  private userAccessList = this.store.select(
    dashboardFeature.selectPermissionList,
  );

  destroy = new Subject<boolean | undefined>();

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdf: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['userAccess'].currentValue !== changes['userAccess'].previousValue
    ) {
      this.userAccess$.next(this.userAccess);
    }
  }

  ngAfterViewInit() {
    combineLatest([this.userAccessList, this.userAccess$])
      .pipe(takeUntil(this.destroy))
      .subscribe(([data, userAccess]) => {
        const componentInstance = this.tooltipDirective.componentRef?.instance;
        this.tooltipDirective.componentRef!.setInput(
          'tooltip',
          'You don’t have access to this action. Contact your admin to add this access to your account.',
        );

        if (!userAccess) {
          if (componentInstance) {
            this.tooltipDirective.componentRef?.setInput('disabled', true);
          }
          this.renderer.removeClass(this.el.nativeElement, 'disabled-element');
          this.cdf.detectChanges();
          return;
        }

        if (data?.includes(this.userAccess)) {
          if (componentInstance) {
            this.tooltipDirective.componentRef?.setInput('disabled', true);
          }
          this.renderer.removeClass(this.el.nativeElement, 'disabled-element');
          this.cdf.detectChanges();
        } else {
          if (componentInstance) {
            this.tooltipDirective.componentRef?.setInput('disabled', false);
          }
          this.renderer.addClass(this.el.nativeElement, 'disabled-element');
          this.cdf.detectChanges();
        }
      });
  }
}
