import { Observable, catchError, map, throwError } from 'rxjs';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@merchant-portal/environments/environment';

@Injectable()
export class BackwardHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.url?.includes(environment.backend_portal_url)) {
            // Modify the response here
            const modifiedBody = {
              ...event.body,
              meta: event.body?.meta ?? event.body.pagination,
            };
            const modifiedResponse = event.clone({ body: modifiedBody });
            return modifiedResponse;
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error && error.error.message) {
          return throwError(
            () =>
              new HttpErrorResponse({
                headers: error.headers,
                status: error.status,
                statusText: error.statusText,
                url: error.url ?? undefined,
                error: {
                  ...error.error,
                  errors: error.error.message,
                },
              })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
