import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from '../component/tooltip.component';

@Directive({
  selector: '[tooltipDirective]',
  standalone: true,
})
export class TooltipDirectiveDirective implements AfterViewInit {
  componentRef: ComponentRef<TooltipComponent> | null = null;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private el: ElementRef,
  ) {}
  ngAfterViewInit(): void {
    this.componentRef = this.viewContainerRef.createComponent(
      TooltipComponent,
      {
        projectableNodes: [[this.el.nativeElement]],
      },
    );
  }

  @Input() tooltipDirective: string | undefined;
  @Input() tooltipStyleClass: string | undefined;
  @Input() tooltipPosition: string | undefined;
}
