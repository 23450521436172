import { Injectable } from '@angular/core';
import { IDisbursementHistoryDetailResponse } from '@merchant-portal/app/models/disbursement/disbursement-history/disbursement-history.interface';
import { Observable } from 'rxjs';

export interface DisbursementHistoryDetailState {
  errorMessage: string;
  isLoading: boolean;
  currentDisbursementHistory: IDisbursementHistoryDetailResponse['data'] | null;
  downloadUrl: string | null;
}

@Injectable()
export abstract class DisbursementHistoryDetailFactoryService {
  abstract vm$: Observable<DisbursementHistoryDetailState>;
  abstract requestDisbursementHistory(param: string): void;
  abstract downloadRecepit(param: string): void;
}
