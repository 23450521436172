import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OtpStore } from '../otp/store/otp.store';
import { LoginStoreFactory } from './factory/login-store.factory';
import { LoginStoreService } from './store/login.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../auth.scss'],
  providers: [
    { provide: LoginStoreFactory, useClass: LoginStoreService },
    OtpStore,
  ],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  vm$ = this.loginStore.vm$;
  emailSubscription: Subscription | undefined;


  constructor(private fb: FormBuilder, private loginStore: LoginStoreFactory) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      isRemember: [false]
    });
  }

  ngOnInit(): void {
    this.observeEmailChanges();
    this.loginStore.checkAccessToken();
  }

  observeEmailChanges() {
    this.emailSubscription = this.loginForm.get('email')?.valueChanges.subscribe(email => {
      if (this.loginForm.get('email')?.valid) {
        this.checkLocalStorageForFingerprint(email);
      }
    });
  }

  checkLocalStorageForFingerprint(email: string) {
    const storedFingerprint = this.loginStore.getFingerPrintId(email);
    if (storedFingerprint) {
      this.loginForm.get('isRemember')?.setValue(true);
    }
    this.loginStore.checkStoredFingerprint(email);
  }


  onSubmit() {
    const { email, password, isRemember } = this.loginForm.value;
    this.loginStore.login({ email, password, isRemember });
  }

  disableOtpRequire() {
    this.loginStore.disableOtpRequire();
  }
}
