import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SvgLoaderComponent } from './svg-loader.component';

@NgModule({
  declarations: [SvgLoaderComponent],
  imports: [CommonModule, AngularSvgIconModule.forRoot({})],
  exports: [SvgLoaderComponent],
})
export class SvgLoaderModule {}
