import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-transaction',
  templateUrl: './create-transaction.component.html',
  styleUrls: ['./create-transaction.component.scss'],
})
export class CreateTransactionComponent {
  private router = inject(Router);

  navigateToSingleTransaction() {
    this.router.navigateByUrl(
      '/disbursement/create-transaction/single-transaction'
    );
  }

  navigateToBulkTransaction() {
    this.router.navigateByUrl(
      '/disbursement/create-transaction/bulk-transaction/new-batch'
    );
  }
}
