<div class="reject-dialog">
  <div class="title-wrap">
    <h2>{{ title }}</h2>
    <pre>{{ subTitle }}</pre>
  </div>
  <div class="form">
    <div class="form-label">Reject Reason</div>
    <input
      pInputText
      [formControl]="reason"
      [ngClass]="{
        'form-invalid': reason.invalid && (reason.dirty || reason.touched)
      }"
    />
    <div
      class="error-text"
      *ngIf="reason.invalid && (reason.dirty || reason.touched)"
    >
      <span class="icon alert"></span>
      <span>Enter reject reason</span>
    </div>
  </div>
  <div *ngIf="isSpecify" class="wrap-button">
    <button (click)="specify()" class="pg-button inverted-btn">Specify Reason</button>
    <button (click)="confirm()" class="pg-button">Next</button>
  </div>
  <div *ngIf="!isSpecify" class="wrap-button">
    <button (click)="confirm()" class="pg-button">Next</button>
  </div>
</div>
