import { Signal } from '@angular/core';

export abstract class CheckPinStoreFactory {
  abstract loading$: Signal<boolean>;
  abstract error$: Signal<string | null>;
  abstract userStep: Signal<'validate-pin' | 'done'>;
  abstract next(pin: string): Promise<void>;
  abstract setWithoutChecking(value: boolean): void;
  abstract setError(value: string | null): void;
}
