import { HttpErrorResponse } from '@angular/common/http';
import { IMenuResponse } from '@merchant-portal/app/models/menu/menu.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MenuAndPermissionsData } from '../services/menu-and-permission.service';

export const DashboardAction = createActionGroup({
  source: 'Disbursement',
  events: {
    getDisbursementFee: emptyProps(),
    getDisbursementSuccess: props<{
      fee: number;
      minAmount: number;
      maxAmount: number;
    }>(),
    getDisbursementFailed: props<{ error: HttpErrorResponse }>(),
    setPreviousUrl: props<{ url: string }>(),
    setNotification: props<{ status: boolean }>(),

    requestUserMenus: props<{ id: string }>(),
    requestUserMenusSuccess: props<{ menus: IMenuResponse }>(),
    requestUserMenusFailed: props<{ error: string }>(),

    getMenuAndPermissions: emptyProps(),
    getMenuAndPermissionsSuccess: props<{ menus: MenuAndPermissionsData[] }>(),
    getMenuAndPermissionsFailed: props<{ error: HttpErrorResponse }>(),

    requestRoute: emptyProps(),
    requestRouteSuccess: props<{ url: string[] }>(),
    requestRouteFailed: props<{ url: string }>(),
  },
});
