import { Component } from '@angular/core';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { Store } from '@ngrx/store';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
@Component({
  selector: 'app-upload-transaction-complete-dialog',
  templateUrl: './upload-transaction-complete-dialog.component.html',
  styleUrls: ['./upload-transaction-complete-dialog.component.scss'],
  providers: [DialogService],
})
export class UploadCompleteDialogComponent {
  title = '';
  body = '';
  closeText = 'Close';
  confirmText = 'Confirm';
  imageClass = '';
  isDoNothing = false;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private store: Store
  ) {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.message;
    this.closeText = this.dialogConfig.data?.closeText ?? 'Close';
    this.confirmText = this.dialogConfig.data?.confirmText ?? 'Confirm';
    this.isDoNothing = this.dialogConfig.data?.isDoNothing;
    this.imageClass = this.dialogConfig.data?.imageClass;
    this.store
      .select(dashboardFeature.selectWaitNotification)
      .subscribe((waitNotification) => {
        if (!waitNotification) {
          this.close();
        }
      });
  }

  close() {
    this.ref.close();
  }

  confirm() {
    if (!this.isDoNothing) {
      this.ref.close(true);
    }
  }
}
