import { HttpErrorResponse } from '@angular/common/http';
import {
  ITeamMemberApiResponse,
  ITeamMemberData,
  ITeamMemberDataSubmit,
  TeamMemberFilter
} from '@merchant-portal/app/models/setting/team-member.interface';
import { IRole } from '@merchant-portal/app/models/user-role/role.interface';
import { createActionGroup, emptyProps } from '@ngrx/store';

export const userListAction = createActionGroup({
  source: 'User List',
  events: {
    //#region Dialog Actions
    showAddUserDialog: emptyProps(),
    showUpdateUserDialog: (id: string) => ({ id }),
    //#endregion

    //#region Permission request
    requestAllPermissions: emptyProps(),
    requestAllPermissionsSuccess: (permissions: IRole[]) => ({ permissions }),
    requestAllPermissionsFailed: (error: HttpErrorResponse) => ({ error }),
    //#endregion
    //#region User List request
    requestAllUsers: (filter?: Partial<TeamMemberFilter>) => ({ filter }),
    requestAllUsersSuccess: (
      users: ITeamMemberApiResponse['data'],
      meta: ITeamMemberApiResponse['meta']
    ) => ({
      users,
      meta,
    }),
    requestAllUsersFailed: (error: HttpErrorResponse) => ({ error }),
    //#endregion
    requestUpdateUser: (id: string) => ({ id }),
    requestDeactiveUser: (id: string) => ({ id }),
    requestActiveUser: (id: string) => ({ id }),
    requestResendInvitationUser: (body: ITeamMemberData) => ({ body }),

    //create edit
    requestCreateTeamMember: (body: ITeamMemberDataSubmit) => ({ body }),
    requestEditTeamMember: (body: ITeamMemberDataSubmit) => ({ body }),

    requestDetailUser: (id: string) => ({ id }),
    requestDetailUserSuccess: (users: ITeamMemberData) => ({
      users,
    }),
    requestDetailUserFailed: (error: HttpErrorResponse) => ({ error }),

    requestCreateTeamMemberSuccess: emptyProps(),
    requestCreateTeamMemberFailed: (error: HttpErrorResponse) => ({ error }),

    requestEditTeamMemberSuccess: emptyProps(),
    requestEditTeamMemberFailed: (error: HttpErrorResponse) => ({ error }),
  },
});
