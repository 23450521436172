import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { BulkTransactionItemConfirmation } from '@merchant-portal/app/models/disbursement/bulk-transaction/bulk-transaction.interface';
import { DefaultValuePipe } from '@merchant-portal/app/pipe/default-value.pipe';
import { DividerModule } from 'primeng/divider';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { SingleTransactionData } from '../../../models/disbursement/single-transaction/single-transaction.interface';

registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-transaction-confirmation-dialog',
  templateUrl: './transaction-confirmation-dialog.component.html',
  styleUrls: ['./transaction-confirmation-dialog.component.scss'],
  providers: [DialogService],
  standalone: true,
  imports: [CommonModule, DefaultValuePipe, DividerModule],
})
export class TransactionConfirmationDialogComponent {
  title = '';
  subTitle = '';
  content: SingleTransactionData;
  bulkContent: BulkTransactionItemConfirmation;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.subTitle = this.dialogConfig.data?.message;
    this.content = this.dialogConfig.data?.content;
    this.bulkContent = this.dialogConfig.data?.bulkContent;
  }

  confirm() {
    if (this.content) {
      this.ref.close(this.content);
    }
    if (this.bulkContent) {
      this.ref.close(this.bulkContent);
    }
  }

  cancel() {
    this.ref.close();
  }
}
