import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-block-loading',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule, BlockUIModule],
  template: `<p-blockUI *ngIf="!custom" [blocked]="show">
      <p-progressSpinner></p-progressSpinner>
    </p-blockUI>
    <div class="custom-loading" *ngIf="custom && show">
      <div class="content">
        <span class="spinner icon progress-spinner"> </span>
        <span *ngIf="title" class="title">{{ title }}</span>
        <span *ngIf="subTitle" class="subTitle">{{ subTitle }}</span>
      </div>
    </div> `,
  styleUrls: ['./block-loading.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockLoadingComponent {
  @Input() show = false;
  @Input() custom = false;
  @Input() title = '';
  @Input() subTitle = '';
}
