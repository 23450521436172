import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { UserAccessService } from '@merchant-portal/app/core/user-access/services/user-access.service';
import { SvgLoaderModule } from '../icon/svg-loader/svg-loader/svg-loader.module';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  providers: [UserAccessService],
  imports: [CommonModule, MatTreeModule, RouterModule, SvgLoaderModule],
})
export class SidebarMenuModule {}
