<div class="container">
  <div
    class="upload-container"
    (drop)="onFileDropped($event)"
    (dragover)="onDragOver($event)"
  >
    <img
      [src]="
        fileName
          ? 'assets/icon/doc-icon.svg'
          : 'assets/icon/doc-upload-icon.svg'
      "
      alt=""
    />
    <p>
      {{ fileName ? fileName : "Drag and drop file here or" }}
      <span
        *ngIf="fileName"
        tabindex="0"
        (keydown.enter)="onRemoveFile()"
        (click)="onRemoveFile()"
        class="file-input-label"
        >Remove</span
      >
      <label *ngIf="!fileName" for="fileInput" class="file-input-label"
        >Choose file</label
      >
    </p>
    <input
      id="fileInput"
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
    />
  </div>
</div>
