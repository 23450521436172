import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-loader',

  templateUrl: './svg-loader.component.html',
  styleUrls: ['./svg-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgLoaderComponent {
  assetPath = 'assets/icon/';
  @Input() url = '';
}
