<body>
    <img [routerLink]="''" class="img-logo" [src]="'../../../assets/images/iki.png'" alt="" />
    <div class="session">
      <form *ngIf="!isCurrentPasswordValid" class="forgot-form" [formGroup]="currentPasswordForm" (ngSubmit)="onSubmitCurrentPassword()">
        <h4>Welcome back!</h4>
        <span>To finish resetting your password, please create a new password</span>
        <div class="input-form">
          <label for="password">New password:</label>
          <span class="p-input-icon-left">
            <i class="pi pi-lock"></i>
            <input
              type="password"
              name="password"
              id="password"
              autocomplete="off"
              formControlName="password"
              pInputText
            />
          </span>
        </div>
        <div class="warning-message">
          <div *ngIf="currentPasswordForm.get('password')?.hasError('required') && currentPasswordForm.get('password')?.touched">
            <i class="pi pi-exclamation-triangle"></i> Password is required.
          </div>
          <div
            *ngIf="
              currentPasswordForm.get('password')?.hasError('invalidPassword') &&
              !currentPasswordForm.get('password')?.hasError('required') &&
              currentPasswordForm.get('password')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Password must have at least
            8 characters, including uppercase, lowercase, number, and symbol.
          </div>
          <div *ngIf="errorMessage !== ''">
            <i class="pi pi-exclamation-triangle"></i> {{errorMessage}}
          </div>
        </div>
        <button pButton class="button-pg" [disabled]="currentPasswordForm.invalid" type="submit">Next</button>
      </form>
      <form *ngIf="isCurrentPasswordValid"  class="forgot-form" [formGroup]="newPasswordForm" (ngSubmit)="onSubmitNewPassword()">
        <h4>Welcome back!</h4>
        <span>Please re-enter your new password</span>
        <div class="input-form">
          <label for="new_password">Re-enter password:</label>
          <span class="p-input-icon-left">
            <i class="pi pi-lock"></i>
            <input
              type="password"
              name="new_password"
              id="new_password"
              autocomplete="off"
              formControlName="new_password"
              pInputText
            />
          </span>
        </div>
        <div class="warning-message">
          <div *ngIf="newPasswordForm.get('new_password')?.hasError('required') && newPasswordForm.get('new_password')?.touched">
            <i class="pi pi-exclamation-triangle"></i> Password is required.
          </div>
          <div
            *ngIf="
              newPasswordForm.get('new_password')?.hasError('invalidPassword') &&
              !newPasswordForm.get('new_password')?.hasError('required') &&
              newPasswordForm.get('new_password')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Password must have at least
            8 characters, including uppercase, lowercase, number, and symbol.
          </div>
          <div *ngIf="errorMessage !== ''">
            <i class="pi pi-exclamation-triangle"></i> {{errorMessage}}
          </div>
        </div>
        <button pButton class="button-pg" [disabled]="newPasswordForm.invalid" type="submit">Next</button>
      </form>
    </div>
  </body>
  