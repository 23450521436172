import { Injectable } from '@angular/core';
import { ITransactionHistoryDetailResponse } from '@merchant-portal/app/models/transaction-history/transaction-history-detail/transaction-history-detail.interface';
import { Observable } from 'rxjs';

export interface TransactionHistoryDetailState {
  errorMessage: string;
  isLoading: boolean;
  currentTransactionHistory: ITransactionHistoryDetailResponse['data'] | null;
  downloadUrl: string | null;
}

@Injectable()
export abstract class TransactionHistoryDetailFactoryService {
  abstract vm$: Observable<TransactionHistoryDetailState>;
  abstract requestTransactionHistory(param: string): void;
}
