import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountBlockedComponent } from './core/account-blocked/account-blocked.component';
import { ChangeForgotPasswordComponent } from './core/change-forgot-password/change-forgot-password.component';
import { envFlagGuard } from './core/flag/guard/env-flag.guard';
import { authGuard, notLoginGuard } from './core/guard/auth.guard';
import { LoginInvitationComponent } from './core/login-invitation/login-invitation.component';
import { LoginComponent } from './core/login/login.component';
import { LogoutComponent } from './core/logout/logout.component';
import { CreatePinStoreFactory } from './core/pin/create-pin/factory/create-pin-store';
import { CreatePinStoreService } from './core/pin/create-pin/services/create-pin-store.service';
import { emptyPinGuard } from './core/pin/guard/empty-pin.guard';
import { DashboardComponent } from './features/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'simulation',
    canActivate: [envFlagGuard],
    data: { flag: 'is_development' },
    loadChildren: () =>
      import('./features/simulation/simulation.module').then(
        (m) => m.SimulationModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./core/login/login.module').then((m) => m.LoginModule),
    component: LoginComponent,
  },
  {
    path: 'login-invitation',
    component: LoginInvitationComponent,
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./core/logout/logout.module').then((m) => m.LogoutModule),
    component: LogoutComponent,
  },
  {
    path: 'forgot-password',
    canActivate: [notLoginGuard],
    loadChildren: () =>
      import('./core/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'account-blocked',
    loadChildren: () =>
      import('./core/account-blocked/account-blocked.module').then(
        (m) => m.AccountBlockedModule
      ),
    component: AccountBlockedComponent,
  },
  {
    path: 'change-forgot-password',
    loadChildren: () =>
      import(
        './core/change-forgot-password/change-forgot-password.module'
      ).then((m) => m.ChangeForgotPasswordModule),
    component: ChangeForgotPasswordComponent,
  },
  {
    path: 'create-pin',
    loadComponent: () =>
      import('./core/pin/create-pin/create-pin.component').then(
        (m) => m.CreatePinComponent
      ),
    canActivate: [emptyPinGuard],
    providers: [
      {
        provide: CreatePinStoreFactory,
        useClass: CreatePinStoreService,
      },
    ],
    loadChildren: () =>
      import('./core/pin/create-pin/create-pin.module').then(
        (m) => m.CreatePinModule
      ),
  },

  {
    path: '', // Set a specific route for the dashboard
    loadChildren: () =>
      import('./features/dashboard/dashboard.routing.module').then(
        (m) => m.DashboardRoutingModule
      ),
    canActivate: [authGuard],
    component: DashboardComponent,
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
