import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { BeneficiaryListApiFatoryService } from '../factory/beneficiary-list-api-fatory.service';
import { EMPTY, Observable, catchError } from 'rxjs';
import { IBeneficiaryListResponse, BeneficiaryListFilter } from 'projects/merchant-portal/src/app/models/disbursement/beneficiary-list/beneficiary-list.interface';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryListApiService extends BeneficiaryListApiFatoryService{

  public backend_portal_url = environment.backend_portal_url + 'api/v1/beneficiary-accounts';

  constructor(private http: HttpClient) {
    super();
  }

  getBeneficiaryList(
    filter: Partial<BeneficiaryListFilter>
  ): Observable<IBeneficiaryListResponse> {

    let params = new HttpParams();

    Object.keys(filter).forEach((key) => {
      const currentFilter: Record<string, any> = filter;
      
      if (currentFilter[key] instanceof Date) {
        const dateParam: Date = currentFilter[key];
        params = params.set(
          key,
          formatDate(dateParam, 'YYYY-MM-DDTHH:mm:ssZ', 'en') as any
        );
      } else {
        if (currentFilter[key])
          params = params.set(key, currentFilter[key] as any);
      }
    });

    return this.http.get<IBeneficiaryListResponse>(this.backend_portal_url, { params }).pipe(
      catchError((val) => {
        return EMPTY;
      })
    );
  }
}
