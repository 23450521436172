import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITransactionHistoryDetailResponse } from '@merchant-portal/app/models/transaction-history/transaction-history-detail/transaction-history-detail.interface';

import { environment } from '@merchant-portal/environments/environment';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionHistoryDetailApiService {
  public backend_portal_url =
    environment.backend_portal_url + 'api/v1/transaction-histories/';

  constructor(private http: HttpClient) {}

  getTransactionHistoryDetail(
    id: string
  ): Observable<ITransactionHistoryDetailResponse> {
    return this.http
      .get<ITransactionHistoryDetailResponse>(
        this.backend_portal_url + 'details/' + id
      )
      .pipe(
        catchError((error) => {
          return throwError(() => new Error(error));
        })
      );
  }
}
