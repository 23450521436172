<div class="dialog-holder">
  <div class="title-wrap">
    <h2>{{ title }}</h2>
    <span class="subTitle" [innerHTML]="body"></span>
  </div>
  <div class="form-holder" *ngFor="let data of formOrder">
    <span class="label">{{ data }}</span>
    <div class="content">
      {{ fromData[data] }}
    </div>
  </div>
  <div class="wrap-button">
    <button
      (click)="close()"
      class="pg-outline-button"
      data-test-id="close-button"
    >
      {{ closeText }}
    </button>
    <button (click)="confirm()" data-test-id="confirm-button" class="pg-button">
      <b> {{ confirmText }}</b>
    </button>
  </div>
</div>
