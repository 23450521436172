import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-transaction-reject-dialog',
  standalone: true,
  imports: [CommonModule, InputTextModule, ReactiveFormsModule, FormsModule],
  templateUrl: './transaction-reject-dialog.component.html',
  styleUrls: ['./transaction-reject-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class TransactionRejectDialogComponent {
  title = '';
  subTitle = '';
  reason = new FormControl('', {
    validators: [Validators.required],
    updateOn: 'blur',
  });
  isSpecify = false;
  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.subTitle = this.dialogConfig.data?.message;
    this.isSpecify = this.dialogConfig.data?.isSpecify;
  }

  confirm() {
    if (this.reason.valid) {
      this.ref.close(this.reason.getRawValue());
    } else {
      this.reason.markAllAsTouched();
    }
  }

  specify() {
    this.ref.close({ specify: true });
  }
}
