import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { passwordValidator } from '@merchant-portal/app/core/guard/password.guard.validator';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    PasswordModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuthService],
})
export class ChangePasswordDialogComponent {
  private fb: FormBuilder = inject(FormBuilder);
  private authService = inject(AuthService);
  passwordForm = this.fb.group({
    reEnterPassword: ['', [Validators.required, passwordValidator()]],
    newPassword: ['', [Validators.required, passwordValidator()]],
  });
  private currentPassword = '';

  errorMessage = signal('');

  errorMapper: Record<string, string> = {
    'ERROR_REQUEST | ERROR_UNAUTHORIZED | invalid password':
      'Password is incorrect.',
    'ERROR_REQUEST | ERROR_DUPLICATE_CHECK | password has been used before':
      'Password has been used before',
  };

  error = computed(() => {
    return this.errorMapper[this.errorMessage()]
      ? this.errorMapper[this.errorMessage()]
      : this.errorMessage();
  });

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.currentPassword = this.dialogConfig.data?.currentPassword;
    this.passwordForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.errorMessage.set('');
    });
  }

  inputType = {
    reEnterPassword: 'password',
    newPassword: 'password',
  };

  toggleInputType(type: keyof typeof this.inputType) {
    this.inputType[type] =
      this.inputType[type] === 'password' ? 'text' : 'password';
  }
  onSubmitNewPassword() {
    this.errorMessage.set('');
    if (
      this.passwordForm.get('reEnterPassword')?.value ===
      this.passwordForm.get('newPassword')?.value
    ) {
      this.authService
        .changePassword({
          newPassword: this.passwordForm.get('reEnterPassword')?.value,
          password: this.currentPassword,
        })
        .pipe(
          catchError(({ error }: HttpErrorResponse) => {
            this.errorMessage.set(error.errors);
            return of('');
          })
        )
        .subscribe((res) => {
          if (res) {
            this.ref.close(true);
          }
        });
    } else {
      this.errorMessage.set('Password do not match.');
    }
  }
}
