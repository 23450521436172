import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastMessageFactory } from './message/toast-message-factory.service';
import { ToastMessageService } from './message/toast-message.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ToastMessageFactory,
      useClass: ToastMessageService,
    },
  ],
})
export class CoreModule {}
