import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from '@merchant-portal/app/components/pagination/pagination.component';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { CoreModule } from 'projects/merchant-portal/src/app/core/core.module';
import { BeneficiaryListComponent } from './beneficiary-list.component';
import { BeneficiaryListApiFatoryService } from './factory/beneficiary-list-api-fatory.service';
import { BeneficiaryListFactoryService } from './factory/beneficiary-list-store-factory';
import { BeneficiaryListApiService } from './services/beneficiary-list.api.service';
import { BeneficiaryListService } from './services/beneficiary-list.service';

@NgModule({
  declarations: [BeneficiaryListComponent],
  imports: [
    PaginationComponent,
    CommonModule,
    RouterModule,
    CardModule,
    CalendarModule,
    CoreModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    PaginatorModule,
  ],
  exports: [BeneficiaryListComponent],
  providers: [
    {
      provide: BeneficiaryListFactoryService,
      useClass: BeneficiaryListService,
    },
    {
      provide: BeneficiaryListApiFatoryService,
      useClass: BeneficiaryListApiService,
    },
  ],
})
export class BeneficiaryListModule {}
