import { MESSAGE_TYPE } from './message.constant';

/**
 * Service to handle toast message
 */
export abstract class ToastMessageFactory {
  /**
   * Post toast message
   *
   * @param body
   */
  abstract postMessage(body: {
    type: MESSAGE_TYPE;
    variant?: string;
    title: string;
    body: string;
  }): void;
}
