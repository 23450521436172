import { Component } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [DialogService],
})
export class ErrorDialogComponent {
  title = '';
  error = '';
  errorList: string[] = [];

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.error = this.dialogConfig.data?.message;
    this.errorList = this.dialogConfig.data?.messageList;
  }

  close() {
    this.ref.close();
  }
}
