import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PinApiService } from '../../api/pin-api.service';
import { ForgotPinStoreFactory } from './factory/forgot-pin-store.factory';
import { ForgotPinDialogStoreService } from './services/change-pin-dialog-store.service';

@Component({
  selector: 'app-forgot-pin-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CodeInputModule,
    CardModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
  ],
  templateUrl: './forgot-pin-dialog.component.html',
  styleUrls: ['./forgot-pin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ForgotPinStoreFactory,
      useClass: ForgotPinDialogStoreService,
    },
    PinApiService,
  ],
})
export class ForgotPinDialogComponent {
  pinValue = '';

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  store = inject(ForgotPinStoreFactory);
  ref = inject(DynamicDialogRef);

  subTitle: Signal<string> = computed(() => {
    const map: Record<string, string> = {
      'validate-pin': 'Please create a 6-digit number PIN',
      'confirm-new-pin': 'Please re-enter your PIN',
    };
    const currentState = this.store.userStep();
    return map[currentState] ?? '';
  });

  _error$ = this.store.error$;
  error$ = computed(() => {
    const mappedError: Record<string, string> = {
      'invalid pin': 'PIN is incorrect',
    };
    return mappedError[this.store.error$() ?? '']
      ? mappedError[this.store.error$() ?? '']
      : this.store.error$();
  });
  isCurrentPin = true;
  isLoading = this.store.loading$;

  constructor() {
    effect(() => {
      if (this.store.userStep() === 'confirm-new-pin') {
        this.codeInput.reset();
      }
    });
    effect(() => {
      const error = this.store.error$();
      if (this.store.userStep() === 'done' && !error) {
        this.ref.close(this.pinValue);
      }
    });
  }

  onCodeChanged($event: string) {
    this.pinValue = $event;
  }

  async next() {
    await this.store.next(this.pinValue);
  }
}
