import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NewRelicAgentService } from '../core/services/new-relic-agent.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private newRelicAgent: NewRelicAgentService = inject(NewRelicAgentService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const requestClone = req.clone({
      setHeaders: {
        'Accept-Language': 'en',
      },
    });

    return next.handle(requestClone).pipe(
      catchError((error) => {
        console.log(error);
        this.newRelicAgent.handleError(error);
        return throwError(error);
      }),
    );
  }
}
