import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IBatchPreviewResponse } from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/batch-preview/batch-preview.interface';

@Injectable()
export abstract class BatchPreviewFactoryService {
  abstract currentBatchPreview$: Observable<IBatchPreviewResponse['data']>;
  abstract currentBatchPreviewFile$: Observable<File>;
  abstract postBatchPreview(req: File): Observable<any | null>;
  abstract validatePreview(req: File): Observable<IBatchPreviewResponse | null>;
}
