<div class="topup-dialog" *ngIf="!isShow">
  <h3>{{ title }}</h3>
  <ng-container>
    <div class="bank-transfer">
      <span>Bank Transfer</span>
    </div>
    <div class="content-wrap" [@fadeInOut]="'show'" *ngFor="let item of body">
      <div
        (click)="selectNode(item)"
        tabindex="0"
        (keydown.enter)="selectNode(item)"
        class="content"
      >
        <div class="wrap-icon">
          <img
            height="30px"
            width="30px"
            class="icon-instruction"
            [src]="'../../../../assets/icon/' + item.logo"
            alt=""
          />
        </div>
        <span>{{ item.name }}</span>
        <div style="flex-grow: 1"></div>
        <i class="pi pi-angle-right"></i>
      </div>
    </div>
  </ng-container>
</div>
<div class="topup-dialog" *ngIf="isShow">
  <h3>{{ title }}</h3>
  <div class="wrap-instruction">
    <div class="instruction" [@fadeInOut]="'hide'">
      <div class="flex-container header-instruction">
        <div class="content-1">
          <img
            class="icon-instruction"
            [src]="'../../../../assets/icon/' + selectedNode?.logo"
            alt=""
          />
        </div>
        <div class="content-2">
          <span style="font-size: 14px; font-weight: 500">
            {{
              selectedNode?.type === BANK_TRANSFER
                ? dialogTitle
                : "Virtual Account"
            }} </span
          ><br /><span
            style="font-size: 12px; font-weight: 500; color: #929292"
            >{{ refrenceNumber }}</span
          >
        </div>
        <div class="content-3">
          <div [class.success-message]="isCopied" *ngIf="isCopied">Copied!</div>
          <button (click)="copyToClipboard()" class="pg-button copy-btn">
            Copy
          </button>
        </div>
      </div>
      <div class="col container">
        <div></div>

        <div class="top-up-intruction-text">How to top up:</div>
        <div class="step-container">
          <div *ngFor="let step of steps(); let i = index" class="step">
            <div class="v-stepper">
              <div class="circle">{{ i + 1 }}</div>
              <div class="line" *ngIf="i < steps.length - 1"></div>
            </div>
            <div
              [innerHTML]="step | appReplaceString : selectedNode"
              class="stepper-content"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="back-btn">
      <button (click)="nodeExpand()" class="pg-button">Back</button>
    </div>
  </div>
</div>
<app-global-spinner></app-global-spinner>
