import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@merchant-portal/environments/environment';

export interface Root {
  code: string;
  data: Data;
}

export interface Data {
  uuid: string;
  merchantId: string;
  amount: number;
  totalAmount: number;
  feeType: string;
  createdAt: string;
  updatedAt: string;
}

export interface IDisbursementsConfigResponse {
  code: string;
  message: string;
  data: IConfigData;
}

export interface IConfigData {
  minAmount: number;
  maxAmount: number;
  feeDetail: FeeDetail;
}

export interface FeeDetail {
  type: string;
  method: string;
  deductionType: string;
  amountType: string;
  amount: number;
  percentage: number;
  taxType: string;
  taxPercentage: number;
  taxAmount: number;
  totalAmount: number;
}

@Injectable()
export class MerchantFeeService {
  url = environment.backend_portal_url;
  http = inject(HttpClient);

  getDisbursementFee() {
    return this.http.get<Root>(this.url + 'api/v1/merchants/fee', {
      params: {
        type: 'DISBURSEMENT',
      },
    });
  }

  getDisbursementsConfigFee() {
    return this.http.get<IDisbursementsConfigResponse>(
      this.url + 'api/v1/disbursements/configs',
    );
  }
}
