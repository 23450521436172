import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { PaginationComponent } from '@merchant-portal/app/components/pagination/pagination.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { CoreModule } from 'projects/merchant-portal/src/app/core/core.module';
import { DisbursementHistoryDetailComponent } from './disbursement-history-detail/disbursement-history-detail.component';
import { DisbursementHistoryComponent } from './disbursement-history.component';

@NgModule({
  declarations: [DisbursementHistoryComponent],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    CalendarModule,
    CoreModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    PaginatorModule,
    BlockLoadingComponent,
    NgxCurrencyDirective,
    PaginationComponent,
    DisbursementHistoryDetailComponent,
  ],

  exports: [DisbursementHistoryComponent],
})
export class DisbursementHistoryModule {}
