import {
  ITeamMemberData,
  ITeamMemberDataSubmit,
  Meta,
  TeamMemberFilter,
} from '@merchant-portal/app/models/setting/team-member.interface';
import { IRole } from '@merchant-portal/app/models/user-role/role.interface';
import { Observable } from 'rxjs';

export abstract class TeamMemberStoreFactory {
  abstract users$: Observable<ITeamMemberData[]>;
  abstract meta$: Observable<Meta | undefined>;
  abstract userDetail$: Observable<ITeamMemberData | undefined>;
  abstract isLoading$: Observable<boolean>;
  abstract isTeamMemberDialogClose$: Observable<boolean>;
  abstract roles: Observable<IRole[]>;
  abstract canResendEmailInvitation(props: {
    email: string;
    createdAt?: string;
  }): boolean;
  abstract requestAllRoles(): void;
  abstract requestAllUsers(filter?: Partial<TeamMemberFilter>): void;
  abstract showAddUserDialog(): void;
  abstract showEditUserDialog(id: string): void;
  abstract requestDetailUsers(id: string): void;
  abstract requestCreateTeamMember(body: ITeamMemberDataSubmit): void;
  abstract requestEditTeamMember(body: ITeamMemberDataSubmit): void;
  abstract showActive(id: string): void;
  abstract showDeactive(id: string): void;
  abstract showResendInvitation(body: ITeamMemberData): void;
  abstract hasPermission(permission: string): boolean;
}
