<div *ngIf="bulkContent" class="transaction-dialog">
  <div class="content-wrap">
    <div class="title-wrap">
      <h2>{{ title }}</h2>
      <span [innerHTML]="subTitle"></span>
    </div>
    <div *ngIf="!bulkContent" class="top-container">
      <div class="top-div">
        <h4>Sender</h4>
        <h3>{{ content?.merchantName }}</h3>
      </div>
      <div class="top-div">
        <h4>Beneficiary</h4>
        <h3>{{ content.beneficiaryAccountName }}</h3>
        <div style="margin-top: -14px">
          <span
            >{{ content.beneficiaryBankName }} &#x2022;
            {{ content.beneficiaryAccountNo }}</span
          >
        </div>
      </div>
    </div>
    <div *ngIf="!bulkContent" class="transaction-detail bottom-div">
      <span class="transaction-detail__amount">Amount</span>
      <span class="transaction-detail__amount transaction-detail__value">
        {{ content.amount | currency : "IDR" : "symbol" : "1.0-0" : "id" }}
      </span>
      <span>Fee</span>
      <span class="transaction-detail__value">{{
        content.fee
          | defaultValue : 0
          | currency : "IDR" : "symbol" : "1.0-0" : "id"
      }}</span>
      <span>Total Amount</span>
      <span class="transaction-detail__value">{{
        +content.amount + +(content.fee | defaultValue : 0)
          | currency : "IDR" : "symbol" : "1.0-0" : "id"
      }}</span>
      <span>Reference ID</span>
      <span class="transaction-detail__value">{{ content.referenceId }}</span>
      <span>Remarks</span>
      <span class="transaction-detail__value">{{ content.remark }}</span>
    </div>
    <!-- for bulk -->
    <div *ngIf="bulkContent" class="transaction-detail bottom-div">
      <span class="transaction-detail__label">Count</span>
      <span class="value">
        {{ bulkContent.count }}
      </span>
      <span class="transaction-detail__label">Amount</span>
      <span class="value">{{
        bulkContent.amount
          | defaultValue : 0
          | currency : "IDR" : "symbol" : "1.0-0" : "id"
      }}</span>
      <span class="transaction-detail__label">Fee</span>
      <span class="value">{{
        bulkContent.fee
          | defaultValue : 0
          | currency : "IDR" : "symbol" : "1.0-0" : "id"
      }}</span>
      <span class="transaction-detail__label">Total Amount</span>
      <span class="transaction-detail__value">{{
        bulkContent.totalAmount | currency : "IDR" : "symbol" : "1.0-0" : "id"
      }}</span>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
      <button (click)="confirm()" class="pg-button">Confirm</button>
    </div>
  </div>
</div>

<div *ngIf="!bulkContent">
  <div class="single-form-header">
    <h2>{{ title }}</h2>
    <span class="sub-title" [innerHTML]="subTitle"></span>
  </div>
  <div class="transaction-data-container">
    <div class="transaction-data-title">Beneficiary Detail</div>
    <div
      style="padding-bottom: 20px"
      class="transaction-data-content-wrap border-bottom"
    >
      <div class="transaction-data-content">
        <div class="label">Sender</div>
        <div>
          {{ content.merchantName }}
        </div>
      </div>
      <div class="transaction-data-content">
        <div class="label">Bank Name</div>
        <div class="value">
          {{ content.beneficiaryBankName }}
        </div>
      </div>
      <div class="transaction-data-content">
        <div class="label">Account Number</div>
        <div>
          {{ content.beneficiaryAccountNo }}
        </div>
      </div>
      <div class="transaction-data-content">
        <div class="label">Beneficiary Name</div>
        <b>
          {{ content.beneficiaryAccountName }}
        </b>
      </div>
    </div>
    <div class="transaction-data-title">Transaction Detail</div>
    <div class="transaction-data-content-wrap">
      <div class="transaction-data-content">
        <div class="label">Amount</div>
        <div class="value">
          {{ content.amount | currency : "IDR" : "symbol" : "1.0-0" : "id" }}
        </div>
      </div>

      <div class="transaction-data-content">
        <div class="label">Fee</div>
        <div class="value">
          {{ content.fee | currency : "IDR" : "symbol" : "1.0-0" : "id" }}
        </div>
      </div>

      <div class="transaction-data-content">
        <div class="label">Total</div>
        <div class="value">
          <b>
            {{
              +content.amount + +(content.fee | defaultValue : 0)
                | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}
          </b>
        </div>
      </div>

      <div class="transaction-data-content">
        <div class="label">Reference ID</div>
        <div class="value">
          {{ content.referenceId }}
        </div>
      </div>

      <div class="transaction-data-content">
        <div class="label">Remarks</div>
        <div>{{ content.remark || "-" }}</div>
      </div>
    </div>
  </div>

  <div class="wrap-button">
    <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
    <button (click)="confirm()" class="pg-button">Confirm</button>
  </div>
</div>
