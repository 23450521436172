import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthTokenResponse } from '@merchant-portal/app/models/login/login.interface';
import { environment } from '@merchant-portal/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth.service';
import {
  ActivateUser,
  ValidateInvitationPayload,
  ValidateInvitationResponse,
} from '../login-invitation.store';

@Injectable({
  providedIn: 'root',
})
export class LoginInvitationApiService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  url = environment.backend_portal_url;

  verifyLinkInvitation(
    payload: ValidateInvitationPayload
  ): Observable<ValidateInvitationResponse> {
    return this.http.post<ValidateInvitationResponse>(
      `${this.url}api/v1/users/validate-invitation`,
      payload
    );
  }

  activateUser(
    payload: ActivateUser,
    token: string,
    activateToken: string
  ): Observable<AuthTokenResponse> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${activateToken}`, // Added space after 'Bearer'
      'X-Invitation-Token': token, // This seems redundant, check if it's necessary
    });
    return this.http.patch<AuthTokenResponse>(
      `${this.url}api/v1/users/activate`,
      payload,
      { headers }
    );
  }
}
