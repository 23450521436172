<div class="item-bottom">
  <span [ngClass]="{
      'header-content__small': variant === 'small',
      'header-content': variant === 'medium'
    }">{{ title }}
    <br />
  </span>
  <div class="group-content">
    <div class="main-content__medium" [ngClass]="{
      'error-text': status === 'failed',
      'success-text': status === 'success',
      'waiting-text': status === 'waiting',
    }">
      <div>
        {{ ammount | defaultValue : "0" }}
      </div>
    </div>
    <div class="main-content__small">
      <div>
        {{ count | defaultValue : "0" }} Transactions
      </div>
    </div>
  </div>

  <div style="margin-left: auto;">
    <a [userAccess]="permission" *ngIf="href" [href]="href" class="footer-content">
      <span>View</span>
    </a>
  </div>

  <span [userAccess]="permission" *ngIf="!href" class="footer-content">View</span>
</div>