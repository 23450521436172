import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { OtpStoreFactory } from './factory/otp-store.factory';
import { OtpStore } from './store/otp.store';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  providers: [{ provide: OtpStoreFactory, useClass: OtpStore }],
  standalone: true,
  imports: [
    CommonModule,
    CodeInputModule,
    CardModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
    DialogModule,
    ToastModule,
  ],
})
export class OtpComponent implements OnChanges {
  @Input() mode: 'dialog' | 'page' = 'page'; // Default mode is 'page'
  @Input() displayOtpDialog = false;
  @Input() countDownStarted = false;
  @Input() event: string = 'user-login';
  @Output() dialogChange = new EventEmitter();
  @Output() isOtpComplete = new EventEmitter();
  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  vm$ = this.otpStore.vm$;
  email = '';

  constructor(private otpStore: OtpStoreFactory) {
    this.vm$.subscribe((state) => {
      if (state.errorMessage) {
        this.codeInput.reset();
      }
      if (state.isAccountBlocked == true) {
        this.displayOtpDialog = false;
      }
      this.email = this.otpStore.getEmail();
      if (state.isEventFromInvitation) {
        this.isOtpComplete.emit(true);
      }
    });
  }

  ngOnChanges() {
    if (this.displayOtpDialog && this.countDownStarted) {
      this.otpStore.startCountdown();
      this.countDownStarted = true;
    }
    if (this.event == 'first-time-login') {
      this.resendOtpCode();
    }
  }

  onCodeChanged(otp: string) {
    this.otpStore.verifyOtp({ otp, event: this.event });
  }

  onDialogHide() {
    this.displayOtpDialog = false;
    this.otpStore.resetState();
    this.codeInput.reset();
    this.dialogChange.emit(this.displayOtpDialog);
  }

  resendOtpCode() {
    this.otpStore.resendOtp(this.event);
  }
}
