<h2 id="page-title">Beneficiary List</h2>
<div class="p-input-icon-right date-filter">
  <span class="p-input-icon-right search-input-holder">
    <i class="pi pi-search"></i>

    <input
      placeholder="Beneficiary Name"
      [(ngModel)]="filter.beneficiaryAccountName"
      pInputText
    />
    <i
      class="pi pi-times"
      *ngIf="filter.beneficiaryAccountName"
      (click)="clearField('beneficiaryAccountName')"
    >
    </i>
  </span>
  <span class="p-input-icon-right search-input-holder">
    <i class="pi pi-search"></i>

    <input
      placeholder="Account No"
      [(ngModel)]="filter.beneficiaryAccountNo"
      pInputText
    />
    <i
      class="pi pi-times"
      *ngIf="filter.beneficiaryAccountNo"
      (click)="clearField('beneficiaryAccountNo')"
    >
    </i>
  </span>
  <p-calendar
    [(ngModel)]="dateFilter"
    [showIcon]="true"
    selectionMode="range"
    #calendarInput
    (ngModelChange)="onFilterDateChange($event)"
    placeholder="Modified Date"
  >
  </p-calendar>
  <button
    *ngIf="dateFilter.length > 0"
    class="p-button p-button-icon dateClear-icon"
    (click)="clearField('startCreatedAt')"
  >
    <i class="pi pi-times"></i>
  </button>
  <p-button
    class="clear-button"
    (onClick)="onFilterChange()"
    label="Search"
  ></p-button>
</div>
<table aria-describedby="page-title">
  <thead>
    <tr>
      <th>Beneficiary Name</th>
      <th>Bank</th>
      <th>Account No.</th>
      <th>Created Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of datas | async">
      <td>{{ data.beneficiaryAccountName }}</td>
      <td>{{ data.beneficiaryBankName }}</td>
      <td>{{ data.beneficiaryAccountNo }}</td>
      <td>{{ data.createdAt | date : "dd MMM yyyy HH:mm:ss" }}</td>
      <td></td>
    </tr>
  </tbody>
</table>
<app-pagination
  *ngIf="totalRecord | async as pagination"
  [page]="pagination.page - 1"
  [totalRecords]="pagination.totalItems"
  [rows]="pagination.perPage"
  (pageChange)="onPageChange($event)"
>
</app-pagination>
