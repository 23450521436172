import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class CreatePinStoreFactory {
  abstract loading$: Observable<boolean>;
  abstract error$: Observable<string | null>;
  abstract submitPin(
    pin: string,
    reEnterPin: string,
    fromInvitation?: boolean
  ): void;
  abstract isSuccess$: Observable<string | null>;
}
