import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewBatchService {
  url = environment.backend_portal_url;

  private bulkPreviewSubject = new BehaviorSubject<any>(null);
  bulkPreviewSubject$ = this.bulkPreviewSubject.asObservable();

  private bulkPreviewFileSubject = new BehaviorSubject<any>(null);
  bulkPreviewFileSubject$ = this.bulkPreviewFileSubject.asObservable();

  private http: HttpClient = inject(HttpClient);

  postBulkPreview(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(
      this.url + 'api/v1/disbursements/bulk/preview',
      formData
    );
  }

  storeBulkPreview(value: any, file: any) {
    this.bulkPreviewSubject.next(value);
    this.bulkPreviewFileSubject.next(file);
  }
}
