import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserAccessDirective } from '@merchant-portal/app/core/user-access/user-access-directive';
import { DefaultValuePipe } from '@merchant-portal/app/pipe/default-value.pipe';

@Component({
  selector: 'app-disbursement-info-card',
  standalone: true,
  imports: [CommonModule, DefaultValuePipe, UserAccessDirective],
  templateUrl: './disbursement-info-card.component.html',
  styleUrls: ['./disbursement-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisbursementInfoCardComponent {
  @Input({ required: false }) count: string | null | undefined = null;
  @Input({ required: false }) ammount: string | null | undefined = null;
  @Input({ required: false }) title = '';
  @Input({ required: false }) href: string | null = null;
  @Input({ required: false }) variant: 'small' | 'medium' | 'large' = 'small';
  @Input({ required: false }) status: 'normal' | 'success' | 'failed' | 'waiting' =
    'normal';
  @Input({ required: true }) permission: string = '';
}
