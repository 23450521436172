import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkTransactionComponent } from './bulk-transaction.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { BreadcrumbComponent } from 'projects/merchant-portal/src/app/components/breadcrumb/breadcrumb.component';
import { PaginatorModule } from 'primeng/paginator';
import { BulkTransactionFactoryService } from './factory/bulk-transaction-store-factory';
import { BulkTransactionService } from './services/bulk-transaction.service';
import { BulkTransactionApiFatoryService } from './factory/bulk-transaction-api-factory.service';
import { BulkTransactionApiService } from './services/bulk-transaction.api.service';
import { RouterModule } from '@angular/router';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CoreModule } from 'projects/merchant-portal/src/app/core/core.module';
import { BulkTransactionRoutingModule } from './bulk-transaction.routing.module';
import { NewBatchModule } from './new-batch/new-batch.module';
import { BatchPreviewModule } from './batch-preview/batch-preview.module';
import { BatchDetailModule } from './batch-detail/batch-detail.module';
import { LoadingModule } from '@merchant-portal/app/components/loading/loading.module';

@NgModule({
  declarations: [BulkTransactionComponent],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    CalendarModule,
    CoreModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    PaginatorModule,
    BulkTransactionRoutingModule,
    BreadcrumbComponent,
    NewBatchModule,
    BatchPreviewModule,
    BatchDetailModule,
    LoadingModule
  ],
  providers: [
    {
      provide: BulkTransactionFactoryService,
      useClass: BulkTransactionService,
    },
    {
      provide: BulkTransactionApiFatoryService,
      useClass: BulkTransactionApiService,
    },
  ],
})
export class BulkTransactionModule {}
