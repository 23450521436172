<div class="error-dialog">
  <div>
    <div class="title-wrap">
      <h2>{{ title }}</h2>
      <pre *ngIf="error">{{ error }}</pre>
      <ul *ngIf="errorList">
        <li *ngFor="let error of errorList">{{ error }}</li>
      </ul>
    </div>
  </div>
</div>
