import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserAccessDirective } from '@merchant-portal/app/core/user-access/user-access-directive';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SvgLoaderModule } from '../icon/svg-loader/svg-loader/svg-loader.module';
import { NavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  providers: [DialogService],
  imports: [
    UserAccessDirective,
    CommonModule,
    RouterModule,
    ButtonModule,
    OverlayPanelModule,
    SvgLoaderModule,
    UserAccessDirective,
  ],
  exports: [NavbarComponent],
})
export class NavbarModule {}
