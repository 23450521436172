import { createFeature, createReducer, on } from '@ngrx/store';

import { IMenuResponse } from '@merchant-portal/app/models/menu/menu.interface';
import { ISidebar } from '@merchant-portal/app/models/sidebar/sidebar.interface';
import { MenuAndPermissionApiService } from '../services/menu-and-permission.service';
import { DashboardAction } from './dashboard.action';

interface IState {
  loading: boolean;
  routeLoading: boolean;
  disbursementFee: number;
  disbursementConfigFee: {
    minAmount: number;
    maxAmount: number;
  };
  prevUrl: string;
  waitNotification: boolean;
  menu: IMenuResponse | null;
  menuList: ISidebar[] | null;
  permissionList: string[] | null;
  accessForbiddenPage: boolean;
}
const initialState: IState = {
  disbursementFee: 2500,
  prevUrl: '',
  loading: false,
  waitNotification: false,
  menu: null,
  menuList: null,
  permissionList: null,
  accessForbiddenPage: false,
  routeLoading: false,
  disbursementConfigFee: {
    minAmount: 10000,
    maxAmount: 250000000,
  },
};
export const dashboardFeature = createFeature({
  name: 'disbursement',
  reducer: createReducer(
    initialState,
    on(
      DashboardAction.getDisbursementFee,
      (state: IState): IState => ({
        ...state,
        loading: true,
      }),
    ),
    on(
      DashboardAction.getDisbursementSuccess,
      (state, { fee, maxAmount, minAmount }): IState => ({
        ...state,
        loading: false,
        disbursementFee: fee,
        disbursementConfigFee: {
          minAmount: minAmount,
          maxAmount: maxAmount,
        },
      }),
    ),
    on(
      DashboardAction.getDisbursementFailed,
      (state): IState => ({
        ...state,
        loading: false,
        disbursementFee: initialState.disbursementFee,
      }),
    ),
    on(
      DashboardAction.setPreviousUrl,
      (state, { url }): IState => ({
        ...state,
        prevUrl: url,
      }),
    ),
    on(
      DashboardAction.setNotification,
      (state, { status }): IState => ({
        ...state,
        waitNotification: status,
      }),
    ),
    on(
      DashboardAction.requestUserMenus,
      (state): IState => ({
        ...state,
        menu: null,
      }),
    ),
    on(
      DashboardAction.requestUserMenusSuccess,
      (state, { menus }): IState => ({
        ...state,
        menu: menus,
      }),
    ),
    on(
      DashboardAction.requestUserMenusFailed,
      (state): IState => ({
        ...state,
        menu: null,
      }),
    ),
    on(
      DashboardAction.getMenuAndPermissions,
      (state: IState): IState => ({
        ...state,
        loading: true,
      }),
    ),
    on(
      DashboardAction.getMenuAndPermissionsSuccess,
      (state, { menus }): IState => {
        const menuList = menus
          .filter((menu) => menu.type === 'sidebar')
          .map(MenuAndPermissionApiService.mapMenuItemToSidebar);
        const permissionList = menus.flatMap((menuItem) =>
          menuItem.permissions.map((permission) => permission.slug),
        );
        const childPermission = menus
          .flatMap((menuItem) =>
            menuItem.children.flatMap((child) => child.permissions),
          )
          .flatMap((permission) => permission.slug);
        return {
          ...state,
          loading: false,
          menuList: menuList,
          permissionList: [...permissionList, ...childPermission],
        };
      },
    ),
    on(
      DashboardAction.getMenuAndPermissionsFailed,
      (state): IState => ({
        ...state,
        loading: false,
        menuList: null,
        permissionList: null,
      }),
    ),
    on(DashboardAction.requestRoute, (state) => ({
      ...state,
      routeLoading: true,
      accessForbiddenPage: false,
    })),
    on(DashboardAction.requestRouteSuccess, (state, { url }) => ({
      ...state,
      routeLoading: false,
    })),
    on(DashboardAction.requestRouteFailed, (state, { url }) => ({
      ...state,
      routeLoading: false,
      accessForbiddenPage: true,
    })),
  ),
});
