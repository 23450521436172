import { ErrorHandler, Injectable } from '@angular/core';
import { NewRelicAgentService } from './new-relic-agent.service';

// The agent loader code executes immediately on instantiation.

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private newRelicAgent: NewRelicAgentService) {
    super();
  }
  override handleError(error: any): void {
    console.error(error);
    this.newRelicAgent.handleError(error);
  }
}
