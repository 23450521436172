<h1 class="p-card-title">Change Password</h1>
<span class="p-card-subtitle">Please create a new password</span>
<form class="form-list" [formGroup]="passwordForm">
  <div class="form-input">
    <label for="new-password"> New Password </label>
    <span class="input-with-icons">
      <i style="z-index: 1" class="icon icon-left pi pi-lock"></i>
      <button
        tabindex="-1"
        class="icon icon-right icon-button"
        (click)="toggleInputType('newPassword')"
      >
        <i
          [ngClass]="
            inputType.newPassword === 'password'
              ? 'pi pi-eye-slash'
              : 'pi pi-eye'
          "
        ></i>
      </button>
      <input
        style="padding-left: 45px"
        formControlName="newPassword"
        id="new-password"
        [type]="inputType.newPassword"
        pInputText
      />
    </span>
    <div class="warning-message">
      <div
        *ngIf="
          passwordForm.get('newPassword')?.hasError('invalidPassword') &&
          !passwordForm.get('newPassword')?.hasError('required')
        "
      >
        <i class="pi pi-exclamation-triangle"></i> Password must have at least 8
        characters, including uppercase, lowercase, number, and symbol.
      </div>
    </div>
  </div>
  <div class="form-input">
    <label for="re-enter-password"> Re-enter Password </label>
    <span class="input-with-icons">
      <i style="z-index: 1" class="icon icon-left pi pi-lock"></i>

      <button
        tabindex="-1"
        class="icon icon-right icon-button"
        (click)="toggleInputType('reEnterPassword')"
      >
        <i
          [ngClass]="
            inputType.reEnterPassword === 'password'
              ? 'pi pi-eye-slash'
              : 'pi pi-eye'
          "
        ></i>
      </button>
      <input
        style="padding-left: 45px"
        formControlName="reEnterPassword"
        id="re-enter-password"
        [type]="inputType.reEnterPassword"
        pInputText
      />
    </span>
    <div class="warning-message">
      <div
        *ngIf="
          passwordForm.get('reEnterPassword')?.hasError('invalidPassword') &&
          !passwordForm.get('reEnterPassword')?.hasError('required')
        "
      >
        <i class="pi pi-exclamation-triangle"></i> Password must have at least 8
        characters, including uppercase, lowercase, number, and symbol.
      </div>
    </div>
  </div>
  <div class="warning-message">
    <div *ngIf="error() !== ''">
      <i class="pi pi-exclamation-triangle"></i> {{ error() }}
    </div>
  </div>
  <button
    [disabled]="
      passwordForm.invalid ||
      !passwordForm.get('newPassword')?.value ||
      error().length > 0
    "
    type="submit"
    (click)="onSubmitNewPassword()"
    class="pg-button"
  >
    Next
  </button>
</form>
