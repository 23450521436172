<div class="dialog-title">Role and Permission</div>
<div style="display: flex; flex-direction: column" class="gap-8px">
  <div class="body-regular">Role Name<span style="color: red">*</span></div>
  <div class="role-name-holder">{{ roleName }}</div>
</div>

<div style="display: flex; flex-direction: column" class="gap-8px">
  <div class="title-text">Permission</div>
  <div class="sub-title">
    Access rights will be granted according to the user level.
  </div>
</div>

<div class="menu-holder" *ngFor="let menuName of menuOrder | async">
  <div style="align-items: center; display: flex; gap: 8px" class="title-text menu-title">
    <div>
      {{ menuName | titlecase }}
    </div>
  </div>
  <div class="permission-holder">
    <ng-container *ngIf="permissionMap | async as result">
      <div *ngFor="let permission of result[menuName]">
        <p-checkbox [(ngModel)]="checked" [binary]="true" [label]="permission" [disabled]="true" />
      </div>
    </ng-container>
  </div>
</div>

<div style="width: 1/3" class="button-group">
  <button (click)="onClose()" class="outline-button pg-button">Close</button>

  <a [userAccess]="'roles-and-permissions.edit'" *ngIf="isDefaultRole" [href]="'setting/roles/edit/' + uuid">
    <button class="pg-button">Edit</button>
  </a>
</div>