import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, NgZone, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { EnvFlagService } from '@merchant-portal/app/core/flag/services/env-flag.service';
import { UserAccessService } from '@merchant-portal/app/core/user-access/services/user-access.service';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { UtilService } from '@merchant-portal/app/services/util.service';
import { Store } from '@ngrx/store';
import { ISidebar } from '../../models/sidebar/sidebar.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  listMenu: ISidebar[] = [];

  treeControl = new NestedTreeControl<ISidebar>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<ISidebar>();

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private userAccessService: UserAccessService,
    private flagService: EnvFlagService,
    private store: Store,
    private util: UtilService,
    private authService: AuthService,
  ) {
    this.router.events.pipe(takeUntilDestroyed()).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.checkActiveMenu();
      }
    });
  }

  ngOnInit(): void {
    this.getListMenu();
  }

  hasChild = (_: number, node: ISidebar) =>
    !!node.children && node.children.length > 0;

  checkActiveMenu() {
    const currentRoute = this.router.url;
    this.treeControl.collapseAll();
    let prevChild: ISidebar | undefined = undefined;
    this.listMenu.forEach((item) => {
      if (currentRoute.includes(item.routerLink)) {
        if (item.routerLink) {
          item.isActive = true;
        }
        item.children?.forEach((child) => {
          if (currentRoute.includes(child.routerLink)) {
            child.isActive = true;
            this.treeControl.expand(item);

            if (prevChild) {
              prevChild.isActive = false;
            }
            prevChild = child;

            item.isActive = false;
          } else {
            child.isActive = false;
          }
        });
      }
    });
  }

  activateMenu(menu: ISidebar) {
    if (menu.routerLink) {
      this.navigateToMenu(menu.routerLink);
      this.resetActiveMenu();
      menu.isActive = true;
    } else {
      this.treeControl.expand(menu);
    }
    // localStorage.setItem('activeMenu', menu.label);
  }

  resetActiveMenu(menuList: ISidebar[] = this.listMenu) {
    menuList.forEach((item) => {
      item.isActive = false;
      if (item.children) {
        this.resetActiveMenu(item.children);
      }
    });
  }

  navigateToMenu(url: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  getListMenu() {
    this.store.select(dashboardFeature.selectMenuList).subscribe((menuList) => {
      if (menuList) {
        this.listMenu = this.util.deepCopy(menuList);
        const disbursementIndex = this.listMenu.findIndex(
          (menu) => menu.label === 'Disbursement',
        );
        this.setDefaultMenu(disbursementIndex);
        this.dataSource.data = this.listMenu;
        this.checkActiveMenu();
      }
    });
  }

  setDefaultMenu(disbursementIndex: number) {
    if (disbursementIndex !== -1) {
      this.listMenu[disbursementIndex]?.children?.unshift({
        label: 'Dashboard',
        routerLink: '/disbursement',
        isActive: false,
        icon: '-',
        customIcon: '-',
        permissions: ['disbursement.view'],
      });
    } else if (
      this.listMenu.length <= 0 &&
      this.authService.getUserInfo()?.role !== 'Crossborder Operator'
    ) {
      this.listMenu.unshift({
        label: 'Disbursement',
        routerLink: '/disbursement',
        icon: '-',
        customIcon: 'send.svg',
        isActive: false,
        children: [
          {
            label: 'Dashboard',
            routerLink: '/disbursement',
            isActive: false,
            icon: '-',
            customIcon: '-',
            permissions: ['disbursement.view'],
          },
        ],
        permissions: ['disbursement.view'],
        hidden: false,
      });
    }
  }
}
