import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { passwordValidator } from '@merchant-portal/app/core/guard/password.guard.validator';
import { ITeamMemberData } from '@merchant-portal/app/models/setting/team-member.interface';
import { DefaultValuePipe } from '@merchant-portal/app/pipe/default-value.pipe';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { Observable } from 'rxjs';
import { TeamMemberStoreFactory } from '../factory/team-member-store';
import { TeamMemberStore } from '../service/team-member-store.service';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-create-edit-team-member.component',
  templateUrl: './create-edit-team-member.component.html',
  styleUrls: ['./create-edit-team-member.component.scss'],
  providers: [
    DialogService,
    {
      provide: TeamMemberStoreFactory,
      useClass: TeamMemberStore,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    BlockLoadingComponent,
    DefaultValuePipe,
  ],
})
export class CreateEditTeamMemberComponent {
  title = '';
  id = '';
  roles = [
    { value: 'admin', name: 'admin' },
    { value: 'maker', name: 'maker' },
    { value: 'checker', name: 'checker' },
  ];
  submitForm: FormGroup;

  loading$ = this.store.isLoading$;
  isTeamMemberDialogClose$ = this.store.isTeamMemberDialogClose$;
  userDetail$: Observable<ITeamMemberData | undefined> = this.store.userDetail$;
  uuid = '';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private store: TeamMemberStoreFactory
  ) {
    this.title = this.dialogConfig.data?.title;
    this.id = this.dialogConfig.data?.id;
    this.submitForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      role: ['', [Validators.required]],
      password: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', [Validators.required, passwordValidator()]],
    });
    this.store.isTeamMemberDialogClose$
      .pipe(takeUntilDestroyed())
      .subscribe((isClosed) => {
        if (isClosed) {
          this.ref.close();
        }
      });
    if (this.id) {
      this.store.requestDetailUsers(this.id);
      this.userDetail$.pipe(takeUntilDestroyed()).subscribe((userDetail) => {
        if (userDetail) {
          this.uuid = userDetail.uuid;
          this.submitForm.patchValue({
            uuid: userDetail.uuid,
            email: userDetail.email,
            name: userDetail.name,
            role: userDetail.role.String,
          });
        }
      });
    }
  }

  close() {
    this.ref.close();
  }

  onSubmit() {
    if (this.id) {
      this.store.requestEditTeamMember({
        ...this.submitForm.getRawValue(),
        uuid: this.uuid,
        roleSlug: this.submitForm.get('role')?.value,
      });
    } else {
      this.store.requestCreateTeamMember({
        ...this.submitForm.getRawValue(),
        roleSlug: this.submitForm.get('role')?.value,
        passwordConfirmation: this.submitForm.get('confirmPassword')?.value,
      });
    }
  }
}
