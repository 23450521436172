import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@merchant-portal/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth.service';

@Injectable()
export class PinApiService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  url = environment.backend_portal_url;

  createPin(
    pin: string
  ): Observable<{ code: string; message: string; data: { pin: string } }> {
    return this.http.post<{
      code: string;
      message: string;
      data: { pin: string };
    }>(`${this.url}api/v1/users/pin`, {
      pin,
    });
  }
  changePin({
    pin,
    newPin,
  }: {
    pin: string;
    newPin: string;
  }): Observable<{ code: string; message: string; data: { pin: string } }> {
    return this.http.put<{
      code: string;
      message: string;
      data: { pin: string };
    }>(`${this.url}api/v1/users/pin`, {
      pin,
      newPin,
    });
  }

  checkPin(pin: string): Observable<{ code: string; message: string }> {
    return this.http.post<{
      code: string;
      message: string;
    }>(`${this.url}api/v1/users/pin/check`, {
      pin,
    });
  }

  resetPin(pin: string): Observable<{
    code: string;
    message: string;
    data: {
      updated: boolean;
    };
  }> {
    return this.http.patch<{
      code: string;
      message: string;
      data: {
        updated: boolean;
      };
    }>(
      `${this.url}api/v1/auth/reset-pin`,
      { pin },
      {
        headers: {
          Authorization: `Bearer ${this.authService.getTempToken()}`,
        },
      }
    );
  }
}
