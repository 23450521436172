<div class="wrap-body">
  <h2>Single Transaction</h2>
  <app-breadcrumb></app-breadcrumb>
  <div class="wrap-session">
    <div class="session">
      <form *ngIf="!isCurrentPasswordValid" class="forgot-form" [formGroup]="singleTransactionForm"
        (ngSubmit)="onSubmit()">
        <div class="input-form">
          <label for="password">Destination Bank <span style="color: red">*</span></label>
          <p-dropdown name="beneficiaryBankCode" id="beneficiaryBankCode" formControlName="beneficiaryBankCode"
            [options]="bankList" optionLabel="name" placeholder="Select" [optionValue]="'code'"
            [filter]="true"></p-dropdown>
        </div>
        <div class="input-form">
          <label for="destAccNumber">Destination Account Number <span style="color: red">*</span></label>
          <div style="display: flex; gap: 15px">
            <input type="text" name="destAccNumber" id="destAccNumber" autocomplete="off"
              formControlName="beneficiaryAccountNo" pInputText />
            <button class="pg-outline-button check-account-button" type="button" [disabled]="isCheckButtonDisabled()"
              (click)="checkAccountNumber()">
              Check Account
            </button>
          </div>
          <p *ngIf="
              this.singleTransactionForm
                .get('beneficiaryAccountNo')
                ?.getError('invalid')
            " class="error-message">
            <span class="icon alert"></span>
            Account number not found
          </p>
        </div>
        <div class="input-form">
          <label for="benificiary">Beneficiary Name <span style="color: red">*</span></label>
          <input type="text" name="benificiary" id="benificiary" autocomplete="off"
            formControlName="beneficiaryAccountName" pInputText />
        </div>
        <div class="input-form">
          <label for="amount">Amount <span style="color: red">*</span></label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">Rp</span>
            <input type="text" name="amount" id="amount" autocomplete="off" formControlName="amount" pInputText
              currencyMask [options]="{
                prefix: '',
                thousands: '.',
                precision: 0,
                align: 'left'
              }" />
          </div>
          <p *ngIf="
              this.singleTransactionForm.get('amount')?.getError('min') &&
              this.singleTransactionForm.get('amount')?.value
            " class="error-message">
            <span class="icon alert"></span>
            Minimum amount is {{configFee().minAmount | currency: "IDR" : "symbol" : "1.0-0" : "id"}}
          </p>
          <p *ngIf="
              this.singleTransactionForm.get('amount')?.getError('max') &&
              this.singleTransactionForm.get('amount')?.value
            " class="error-message">
            <span class="icon alert"></span>
            Maximum amount is {{configFee().maxAmount | currency: "IDR" : "symbol" : "1.0-0" : "id"}}
          </p>
        </div>
        <div class="input-form">
          <label for="referenceId">
            Reference ID <span style="color: red">*</span></label>
          <input pInputText name="referenceId" id="referenceId" formControlName="referenceId" />
        </div>
        <div class="input-form">
          <label for="remark"> Remarks </label>
          <span class="p-input-icon-right">
            <i class="input-icon">{{ singleTransactionForm.get("remark")?.value.length }}/20</i>
            <input rows="5" cols="30" pInputTextarea name="remark" id="remark" autocomplete="off"
              formControlName="remark" maxlength="20" placeholder="Hint Text" />
          </span>
          <p *ngIf="
              this.singleTransactionForm.get('remark')?.getError('maxlength')
            " class="error-message">
            <span class="icon alert"></span>
            Note is too long, maximum is 20 characters
          </p>
        </div>
        <div></div>
        <div class="button-section">
          <button [routerLink]="['/disbursement/create-transaction']" class="heading-medium pg-outline-button"
            type="reset">
            Cancel
          </button>
          <button [disabled]="
              singleTransactionForm.invalid ||
              this.singleTransactionForm.get('amount')?.value === 0
            " pButton class="pg-button" type="submit">
            Create
          </button>
        </div>
      </form>
    </div>
  </div>
</div>