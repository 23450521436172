import { Component, Input, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { EnvFlagService } from '@merchant-portal/app/core/flag/services/env-flag.service';
import { AuthService } from '../../core/auth.service';
import { IUserInfo } from '../../models/login/login.interface';
import { DialogComponentService } from '../dialog/dialog.component.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  private flag = inject(EnvFlagService);
  hideHelpCenter = !this.flag.getFlag('show_help_center');
  hideNotification = !this.flag.getFlag('show_notfication');
  hideMyBusiness = !this.flag.getFlag('show_my_business');
  hideDeveloperSetting = !this.flag.getFlag('show_developer_setting');
  isAccountOpen = false;
  isNotificationOpen = false;

  @Input() userData: IUserInfo | null | undefined;
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogComponentService: DialogComponentService,
    private ngZone: NgZone
  ) {}
  logout() {
    this.authService.logout();
    this.ngZone.run(() => {
      this.router.navigate(['/logout']);
    });
  }
  openDropDown() {
    this.isAccountOpen = !this.isAccountOpen;
  }
  openNotification() {
    this.isNotificationOpen = !this.isNotificationOpen;
  }
  showHelpCenterDialog() {
    this.dialogComponentService.openHelpCenterDialog({
      width: '700px',
      height: '500px',
      body: {
        title: 'Help Center',
        message: 'Please contact Helpdesk via email for assistance.',
      },
    });
  }
}
