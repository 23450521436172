import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { DefaultValuePipe } from '@merchant-portal/app/pipe/default-value.pipe';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-transaction-created-dialog',
  templateUrl: './transaction-created-dialog.component.html',
  styleUrls: ['./transaction-created-dialog.component.scss'],
  providers: [DialogService],
  standalone: true,
  imports: [CommonModule, DefaultValuePipe],
})
export class TransactionCreatedDialogComponent {
  title = '';
  body = '';
  failedType = '';
  showDownload = false;
  fileFailed = '';
  failedTypeSubtitle = '';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.message;
    this.failedType = this.dialogConfig.data?.failedType;
    this.showDownload = this.dialogConfig.data?.showDownload;
    this.fileFailed = this.dialogConfig.data?.fileFailed;
    this.failedTypeSubtitle = this.dialogConfig.data?.failedTypeSubtitle;
  }

  close() {
    this.ref.close();
  }

  downloadTemplate() {
    window.open(this.fileFailed, '_blank', 'noopener');
  }
}
