<div class="transaction-dialog">
  <div class="content-wrap">
    <div class="title-wrap">
      <h3>{{ title }}</h3>
      <span [innerHTML]="subTitle"></span>
    </div>
    <div class="bottom-container">
      <div *ngFor="let item of content; let lastItem = last">
        <div [attr.data-test-id]="item.result" class="bottom-div">
          <div class="bottom-item" style="text-align: left">
            <div data-test-id="label-result" [ngClass]="'status' + item.result">
              <span>{{ item.result }}</span>
            </div>
            <span data-test-id="label-count">Count</span>
            <span data-test-id="label-amount">Amount</span>
            <span data-test-id="label-fee" *ngIf="item.result !== 'INVALID'">Fee</span>
            <span data-test-id="label-total-amount" *ngIf="item.result !== 'INVALID'">Total Amount</span>
          </div>
          <div class="bottom-item" style="text-align: right">
            <span data-test-id="value-count">{{ item.count }}</span>
            <span data-test-id="value-amount">{{
              item.amount | currency : "IDR" : "symbol" : "1.0-0" : "id"
              }}</span>
            <span data-test-id="value-fee" *ngIf="item.result !== 'INVALID'">{{
              item.fee | currency : "IDR" : "symbol" : "1.0-0" : "id"
              }}</span>
            <span data-test-id="value-total-amount" *ngIf="item.result !== 'INVALID'" style="font-weight: 700">{{
              item.totalAmount | currency : "IDR" : "symbol" : "1.0-0" : "id"
              }}</span>
          </div>
        </div>
        <hr width="90%" style="margin-bottom: -15px" *ngIf="!lastItem" />
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
      <button (click)="confirm()" class="pg-button">Confirm</button>
    </div>
  </div>
</div>