import { Component } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  providers: [DialogService],
})
export class SuccessDialogComponent {
  title = '';
  body = '';
  closeText = 'Close';
  closeValue = false;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.message;
    this.closeText = this.dialogConfig.data?.closeText ?? 'Close';
    this.closeValue = this.dialogConfig.data?.closeValue ?? false;
  }

  close() {
    this.ref.close(this.closeValue);
  }
}
