import { Injectable } from '@angular/core';
import { BeneficiaryListFactoryService } from '../factory/beneficiary-list-store-factory';
import {
  Meta,
  BeneficiaryListFilter,
} from 'projects/merchant-portal/src/app/models/disbursement/beneficiary-list/beneficiary-list.interface';
import {
  BehaviorSubject,
  Observable,
  map,
  mergeMap,
  share,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiaryListApiFatoryService } from '../factory/beneficiary-list-api-fatory.service';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryListService extends BeneficiaryListFactoryService {
  private _isLoading$ = new BehaviorSubject(false);

  override currentBeneficiaryListFilter$: Observable<
    Partial<BeneficiaryListFilter>
  > = this.activeRouter.queryParams.pipe(
    map((value) => ({
      beneficiaryAccountName: value['beneficiaryAccountName'],
      beneficiaryAccountNo: value['beneficiaryAccountNo'],
      startCreatedAt: value['startCreatedAt'],
      endCreatedAt: value['endCreatedAt'],
      page: value['page'],
    }))
  );

  private currentBeneficiaryListResponse$ =
    this.currentBeneficiaryListFilter$.pipe(
      tap(() => this._isLoading$.next(true)),
      mergeMap((value) =>
        this.beneficiaryListApiFatoryService.getBeneficiaryList(value)
      ),
      tap(() => this._isLoading$.next(false)),
      share()
    );

  override currentBeneficiaryList$ = this.currentBeneficiaryListResponse$.pipe(
    map((value) => value.data)
  );

  override currentBeneficiaryListPagination$: Observable<Meta> =
    this.currentBeneficiaryListResponse$.pipe(map((value) => value.meta));

  override requestBeneficiaryList(param: Partial<BeneficiaryListFilter>): void {
    this.router.navigate([], {
      queryParams: {
        page: param.page,
        beneficiaryAccountName: param.beneficiaryAccountName,
        beneficiaryAccountNo: param.beneficiaryAccountNo,
        startCreatedAt: param.startCreatedAt?.toISOString(),
        endCreatedAt: param.endCreatedAt?.toISOString(),
      },
      relativeTo: this.activeRouter,
      replaceUrl: true,
    });
  }

  constructor(
    private beneficiaryListApiFatoryService: BeneficiaryListApiFatoryService,
    private activeRouter: ActivatedRoute,
    private router: Router
  ) {
    super();
  }
}
