<span class="p-card-title">Change Password</span>
<span class="p-card-subtitle">Please enter your current password</span>
<form class="form-list" [formGroup]="passwordForm">
  <div class="form-input">
    <label for="re-enter-password"> Current Password </label>
    <span class="input-with-icons">
      <i style="z-index: 1" class="icon icon-left pi pi-lock"></i>

      <button
        type="button"
        tabindex="-1"
        class="icon icon-right icon-button"
        (click)="toggleInputType('password')"
      >
        <i
          [ngClass]="
            inputType.password === 'password' ? 'pi pi-eye-slash' : 'pi pi-eye'
          "
        ></i>
      </button>
      <input
        style="padding-left: 45px"
        formControlName="password"
        id="re-enter-password"
        [type]="inputType.password"
        pInputText
      />
    </span>
  </div>
  <div class="warning-message">
    <div *ngIf="error() !== ''">
      <i class="pi pi-exclamation-triangle"></i> {{ error() }}
    </div>
  </div>
  <button
    [disabled]="!passwordForm.get('password')?.value"
    type="submit"
    (click)="onSubmitNewPassword()"
    class="pg-button"
  >
    Next
  </button>
</form>
