<p-toast position="top-center" key="notification">
  <ng-template let-message pTemplate="message" let-closeFn="closeFn">
    <div class="toast-container">
      <section>
        <span *ngIf="message.data?.title" class="toast-title">
          {{ message.data.title }}
        </span>
        <br *ngIf="message.data?.title" />
        <span class="toast-message" [innerHTML]="message.data.body"></span>
      </section>
    </div>
  </ng-template>
</p-toast>
<div *ngIf="!isConnected" style="display: none" class="connection-status">
  Disconnected from STOMP server.
</div>
