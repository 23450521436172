import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { FileUploadInterface } from '../../models/file-upload/file-upload.interface';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [CommonModule, FileUploadModule, ToastModule],
  providers: [MessageService],
  standalone: true,
})
export class FileUploadComponent {
  @Output() fileContent = new EventEmitter<any>();
  @Input() template: string[] = [];
  @Input() type: string[] = [];
  fileName = '';

  onFileDropped($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    const files = $event.dataTransfer?.files;
    if (files && files.length > 0) {
      const file = files[0];
      this.fileName = file.name;
      this.readFile(file);
    }
  }

  onFileSelected(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      this.fileName = file.name;
      this.readFile(file);
    }
  }

  onDragOver($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  readFile(file: File) {
    const emitValue: FileUploadInterface = { data: file, errors: [] };
    const fileExtension = '.' + file?.name?.split('.')?.pop()?.toUpperCase();
    if (
      this.type.some((extension) => {
        extension = extension.toUpperCase();
        return (
          extension === fileExtension || extension === fileExtension.slice(1)
        );
      })
    ) {
      this.fileContent.emit(emitValue);
    } else {
      emitValue.errors.push('File type must be ' + this.type.toString());
      this.fileContent.emit(emitValue);
    }
  }

  onRemoveFile() {
    this.fileName = '';
    this.fileContent.emit([]);
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the value
    }
  }
}
