<div class="transaction-dialog" *ngIf="vm$ | async as vm">
  <div *ngIf="!isDisbursement(vm.currentTransactionHistory?.type)" class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.createdAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Type</span>
          <span class="black-item">
            {{
            vm.currentTransactionHistory
            ? typeLabelMap[
            vm.currentTransactionHistory | appTransactionType
            ]
            : ""
            }}
          </span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.id
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentTransactionHistory?.amount
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" sty class="pg-button">Close</button>
    </div>
  </div>

  <div *ngIf="isDisbursement(vm.currentTransactionHistory?.type)" class="content-wrap">
    <div class="bottom-container">
      <div class="bottom-div">
        <div class="bottom-item">
          <span>Created Date</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.createdAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Last Update</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.updatedAt | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Type</span>
          <span class="black-item">
            {{
            vm.currentTransactionHistory
            ? typeLabelMap[
            vm.currentTransactionHistory | appTransactionType
            ]
            : ""
            }}
          </span>
        </div>
        <div class="bottom-item">
          <span>Created By</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.createdBy
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Sender</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.senderName
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Reference ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.details?.referenceId
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Remarks</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.remarks
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Failed Reason</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.failedReason
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Amount</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentTransactionHistory?.amount
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
      </div>
      <hr />
      <div class="bottom-div">
        <div class="bottom-item">
          <span class="black-item" style="font-weight: 700">Disbursement Detail</span>
        </div>
        <div class="bottom-item">
          <span>Transaction Status</span>
          <span [ngClass]="'status' + vm.currentTransactionHistory?.status">{{
            vm.currentTransactionHistory?.status
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Transaction ID</span>
          <span class="black-item" style="width: 223px; text-align: right">{{
            vm.currentTransactionHistory?.id
            }}</span>
        </div>

        <div class="bottom-item">
          <span>Bank Reference</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.bankReference ?? "-"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Bank Name</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.bankName
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Account Number</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.accountNumber
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Beneficiary</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.beneficiary
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Fee</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.fee
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Total</span>
          <span class="black-item" style="font-weight: 700">{{
            vm.currentTransactionHistory?.details?.total
            | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
        </div>
      </div>
      <hr />
      <div class="bottom-div" style="padding-bottom: 23px">
        <div class="bottom-item">
          <span class="black-item" style="font-weight: 700">Approval Detail</span>
        </div>
        <div class="bottom-item">
          <span>Approval Status</span>
          <div>
            <span [ngClass]="
                'status' + vm.currentTransactionHistory?.details?.approvalStatus
              ">{{ vm.currentTransactionHistory?.details?.approvalStatus }}</span>
          </div>
        </div>
        <div class="bottom-item">
          <span>Approval Date</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.approvalDate
            | date : "medium"
            }}</span>
        </div>
        <div class="bottom-item">
          <span>Approved By</span>
          <span class="black-item">{{
            vm.currentTransactionHistory?.details?.approvalBy
            }}</span>
        </div>
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" sty class="pg-button">Close</button>
    </div>
  </div>
  <app-block-loading [show]="vm.isLoading"></app-block-loading>
</div>