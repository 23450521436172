import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UserAccessService } from '@merchant-portal/app/core/user-access/services/user-access.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { MenuAndPermissionApiService } from '../services/menu-and-permission.service';
import { MerchantFeeService } from '../services/merchant-fee.service';
import { DashboardAction } from './dashboard.action';
@Injectable({
  providedIn: 'root',
})
export class DashboardEffects {
  private merchantFeeApi = inject(MerchantFeeService);
  private menuAndPermissionsApi = inject(MenuAndPermissionApiService);
  private userAccessService = inject(UserAccessService);

  private actions$ = inject(Actions);

  getDisbursement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.getDisbursementFee),
      mergeMap(() =>
        this.merchantFeeApi.getDisbursementsConfigFee().pipe(
          map((response) =>
            DashboardAction.getDisbursementSuccess({
              maxAmount: response.data?.maxAmount ?? 250000000,
              minAmount: response.data?.minAmount ?? 10000,
              fee:
                response.data?.feeDetail.totalAmount ??
                response.data?.feeDetail.amount ??
                0,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DashboardAction.getDisbursementFailed({
                error: error?.error?.errors,
              }),
            ),
          ),
        ),
      ),
    );
  });

  getUserMenus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.requestUserMenus),
      mergeMap((action) =>
        this.userAccessService.getUserMenu(action.id).pipe(
          map((response) =>
            DashboardAction.requestUserMenusSuccess({
              menus: response,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(DashboardAction.requestUserMenusFailed({ error: error.error })),
          ),
        ),
      ),
    );
  });

  getMenuAndPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardAction.getMenuAndPermissions),
      mergeMap(() =>
        this.menuAndPermissionsApi.getMenuAndPermissions().pipe(
          map((response) =>
            DashboardAction.getMenuAndPermissionsSuccess({
              menus: response?.data ?? [],
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              DashboardAction.getMenuAndPermissionsFailed({
                error: error?.error?.errors,
              }),
            ),
          ),
        ),
      ),
    );
  });
}
