<div *ngIf="(step$ | async) === 'password'" class="app-bar">
  <img height="50px" [src]="'./assets/images/iki.png'" alt="" />
</div>
<p-card
  *ngIf="(step$ | async) === 'password'"
  header="Create Password"
  subheader="Please create a password for logging in to your account. Password must contain at least 1 uppercase, 1 symbol, and 1 number."
>
  <form
    class="form-list"
    [formGroup]="passwordForm"
    (ngSubmit)="onNextPassword()"
  >
    <div class="form-input">
      <label for="new-password"> New Password </label>
      <span class="input-with-icons">
        <i style="z-index: 1" class="icon icon-left pi pi-lock"></i>
        <button
          class="icon icon-right icon-button"
          type="button"
          (click)="toggleInputType('password')"
        >
          <i
            [ngClass]="
              inputType.password === 'password'
                ? 'pi pi-eye-slash'
                : 'pi pi-eye'
            "
          ></i>
        </button>
        <input
          style="padding-left: 45px"
          formControlName="password"
          id="new-password"
          [type]="inputType.password"
          pInputText
        />
      </span>
      <div class="warning-message">
        <div
          *ngIf="
            passwordForm.get('password')?.hasError('required') &&
            passwordForm.get('password')?.touched
          "
        >
          <i class="pi pi-exclamation-triangle"></i> Password is required.
        </div>
        <div
          *ngIf="
            passwordForm.get('password')?.hasError('invalidPassword') &&
            !passwordForm.get('password')?.hasError('required')
          "
        >
          <i class="pi pi-exclamation-triangle"></i> Password must have at least
          8 characters, including uppercase, lowercase, number, and symbol.
        </div>
      </div>
    </div>
    <div class="form-input">
      <label for="re-enter-password"> Re-enter Password </label>
      <span class="input-with-icons">
        <i style="z-index: 1" class="icon icon-left pi pi-lock"></i>
        <button
          class="icon icon-right icon-button"
          type="button"
          (click)="toggleInputType('reEnterPassword')"
        >
          <i
            [ngClass]="
              inputType.reEnterPassword === 'password'
                ? 'pi pi-eye-slash'
                : 'pi pi-eye'
            "
          ></i>
        </button>
        <input
          style="padding-left: 45px"
          formControlName="reEnterPassword"
          id="re-enter-password"
          [type]="inputType.reEnterPassword"
          pInputText
        />
      </span>
      <div class="warning-message">
        <div
          *ngIf="
            passwordForm.get('reEnterPassword')?.touched &&
            passwordForm.get('reEnterPassword')?.value !=
              passwordForm.get('password')?.value
          "
        >
          <i class="pi pi-exclamation-triangle"></i> Passwords do not match.
        </div>
        <div
          *ngIf="
            passwordForm.get('reEnterPassword')?.hasError('required') &&
            passwordForm.get('reEnterPassword')?.touched
          "
        >
          <i class="pi pi-exclamation-triangle"></i> Password is required.
        </div>
        <div
          *ngIf="
            passwordForm.get('reEnterPassword')?.hasError('invalidPassword') &&
            !passwordForm.get('reEnterPassword')?.hasError('required')
          "
        >
          <i class="pi pi-exclamation-triangle"></i> Password must have at least
          8 characters, including uppercase, lowercase, number, and symbol.
        </div>
      </div>
    </div>
    <div></div>
    <button class="pg-button" type="submit">Next</button>
  </form>
</p-card>

<app-create-pin
  *ngIf="(step$ | async) === 'pin'"
  (pinCreated)="onPinCreated($event)"
  [fromInvitation]="true"
></app-create-pin>
<app-otp
  *ngIf="(step$ | async) === 'otp'"
  [displayOtpDialog]="(step$ | async) === 'otp'"
  [event]="'first-time-login'"
  (isOtpComplete)="onOtpComplete($event)"
  mode="page"
></app-otp>
