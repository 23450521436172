<h2 id="page-title">Bulk Transaction</h2>
<app-breadcrumb></app-breadcrumb>
<button pButton class="pg-button" type="button" (click)="createBatch()">
  <img
    class="custom-icon"
    [src]="'../../../../../../assets/icon/upload-file.svg'"
    alt="upload"
  />
  New Batch
</button>
<div style="overflow: auto;">
  <table aria-describedby="page-title">
    <thead>
      <tr>
        <th>Batch No.</th>
        <th>Uploaded Date</th>
        <th>Uploaded By</th>
        <th>Total Trx</th>
        <th>Total Amount</th>
        <th>Status</th>
        <th>Approved</th>
        <th>Rejected</th>
        <th>Success</th>
        <th>Failed</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of datas | async">
        <td
          [routerLink]="[
            '/disbursement/create-transaction/bulk-transaction/detail',
            data.uuid
          ]"
          class="batch-no"
        >
          {{ getLastSegment(data.uuid) }}
        </td>
        <td>{{ data.createdAt | date : "dd MMM yyyy HH:mm:ss" }}</td>
        <td>{{ data.createdBy }}</td>
        <td>{{ data?.totalTrx ? data?.totalTrx : 0 }}</td>
        <td>{{ data?.totalAmount | number : "1.0-0" : "id" }}</td>
        <td>{{ data.status }}</td>
        <td>{{ data?.totalApproved ? data?.totalApproved : 0 }}</td>
        <td>{{ data?.totalRejected ? data?.totalRejected : 0 }}</td>
        <td>{{ data?.totalSuccess ? data?.totalSuccess : 0 }}</td>
        <td>{{ data?.totalFailed ? data?.totalFailed : 0 }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</div>

<p-paginator
  *ngIf="totalRecord | async as pagination"
  [totalRecords]="pagination.totalItems"
  [rows]="pagination.perPage"
  (onPageChange)="onPageChange($event)"
>
</p-paginator>
<app-global-spinner></app-global-spinner>