import { HttpErrorResponse } from '@angular/common/http';
import { Meta } from '@merchant-portal/app/models/disbursement/disbursement.interface';
import {
  IRole,
  IRolePermissionData,
  IRoleRequest,
} from '@merchant-portal/app/models/user-role/role.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const viewRoleAction = createActionGroup({
  source: 'View Role',
  events: {
    requestRoleList: (param: Partial<IRoleRequest>) => ({ param }),
    requestRoleListSuccess: props<{ data: IRole[]; meta: Meta }>(),
    requestRoleListFailed: props<{ error: any }>(),

    requestUserConfirmationToDeleteRole: props<{ id: string; name: string }>(),

    requestDeleteRole: props<{ id: string; name: string }>(),
    requestDeleteRoleSuccess: props<{ id: string; name: string }>(),
    requestDeleteRoleFailed: props<{ error: HttpErrorResponse }>(),

    requestShowRoleDetail: props<{ id: string }>(),

    requestShowRoleDetailSuccess: props<{ data: IRolePermissionData[] }>(),

    requestShowRoleDetailFailed: props<{ error: string }>(),

    showCustomRoleMaximumDialog: emptyProps(),
  },
});
