import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../../../environments/environment';
import { BatchPreviewApiFatoryService } from '../factory/batch-preview-api-factory.service';
import { catchError, of } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class BatchPreviewApiService extends BatchPreviewApiFatoryService {
  public postApiUrl =
    environment.backend_portal_url + 'api/v1/disbursements/bulk/upload';
  public validateUrl =
    environment.backend_portal_url + 'api/v1/disbursements/bulk/validate';

  constructor(private http: HttpClient) {
    super();
  }

  postBatchPreview(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(this.postApiUrl, formData);
  }

  validateBatchPreview(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(this.validateUrl, formData).pipe(
      catchError((val) => {
        return of(val);
      })
    );
  }
}
