import { Injectable } from '@angular/core';
import { BatchDetailFactoryService } from '../factory/batch-detail-store-factory';
import {
  Meta,
  BatchDetailFilter,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/batch-detail/batch-detail.interface';
import {
  BehaviorSubject,
  Observable,
  map,
  mergeMap,
  shareReplay,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BatchDetailApiFatoryService } from '../factory/batch-detail-api-factory.service';

@Injectable({
  providedIn: 'root',
})
export class BatchDetailService extends BatchDetailFactoryService {
  private _isLoading$ = new BehaviorSubject(false);

  override currentBatchDetailFilter$: Observable<Partial<BatchDetailFilter>> =
    this.activeRouter.queryParams.pipe(
      map((value) => ({
        page: value['page'],
        bulkId: value['bulkId'],
      }))
    );

  private currentBatchDetailResponse$ = this.currentBatchDetailFilter$.pipe(
    mergeMap((value) => this.batchDetailApiFatoryService.getBatchDetail(value)),
    tap(() => this._isLoading$.next(false)),
    shareReplay(1)
  );

  private currentBatchDetailNotUploadedResponse$ =
    this.currentBatchDetailFilter$.pipe(
      mergeMap((value) =>
        this.batchDetailApiFatoryService.getBatchDetailNotUploaded(
          value.bulkId || ''
        )
      ),
      tap(() => this._isLoading$.next(false)),
      shareReplay(1)
    );

  override currentBatchDetailNotUpload$ =
    this.currentBatchDetailNotUploadedResponse$.pipe(
      map((value) => value.data)
    );

  override currentBatchDetail$ = this.currentBatchDetailResponse$.pipe(
    map((value) => value.data)
  );

  override currentBatchDetailPagination$: Observable<Meta> =
    this.currentBatchDetailResponse$.pipe(map((value) => value.meta));

  override requestBatchDetail(param: Partial<BatchDetailFilter>): void {
    this.router.navigate([], {
      queryParams: {
        page: param.page,
        bulkId: param.bulkId,
      },
      relativeTo: this.activeRouter,
      replaceUrl: true,
    });
  }

  override requestBatchDetailNotUpload(id: string): void {
    this.router.navigate([], {
      relativeTo: this.activeRouter,
      replaceUrl: true,
    });
  }

  constructor(
    private batchDetailApiFatoryService: BatchDetailApiFatoryService,
    private activeRouter: ActivatedRoute,
    private router: Router
  ) {
    super();
  }
}
