<div [tooltipDisabled]="disabled" tooltipStyleClass="tooltip-global" tooltipPosition="bottom"
    [pTooltip]="tooltipContent">
    <ng-content></ng-content>

</div>




<ng-template #tooltipContent>
    <div class="tooltip-content">
        {{tooltip}}
    </div>
</ng-template>