import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  ILoginApiResponse,
  ILoginSubmit,
  ILoginUserDetailsApiResponse,
  IUserInfo,
} from '../models/login/login.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = environment.backend_portal_url;
  private http = inject(HttpClient);

  refreshUserData() {
    const load_url = this.url + 'api/v1/user-detail';
    return this.http
      .get<ILoginUserDetailsApiResponse>(load_url, {
        params: new HttpParams().set('email', this.getUserInfo()?.email ?? ''),
      })
      .pipe(tap((data) => this.setUserInfo(data.data as IUserInfo)));
  }

  login(loginForm: ILoginSubmit, fingerprint: string): Observable<ILoginApiResponse> {
    const load_url = this.url + 'api/v1/auth/login';
    const headers = new HttpHeaders({
      'X-Device-Identifier': fingerprint,
    });
    return this.http.post<ILoginApiResponse>(load_url, loginForm, { headers });
  }

  refreshToken() {
    const load_url = this.url + 'api/v1/auth/refresh';
    return this.http.post(load_url, {
      email: this.getEmail(),
      refreshToken: this.getRefreshToken(),
    });
  }

  changePassword(obj: any) {
    const load_url = this.url + 'api/v1/change-password';
    return this.http.patch(load_url, obj);
  }

  forgotPassword(password: string) {
    const load_url = this.url + 'api/v1/auth/reset-password';

    return this.http.patch(
      load_url,
      { password },
      {
        headers: {
          Authorization: `Bearer ${this.getTempToken()}`,
        },
      }
    );
  }

  logout() {
    const getEmail = this.getUserInfo()?.email;
    const currentFingerprint = getEmail + 'deviceFingerprint';
    const deviceFingerprintsToKeep: Record<string, string> = {};

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key && key.includes('deviceFingerprint')) {
        deviceFingerprintsToKeep[key] = localStorage.getItem(key)!;
      }
    }

    const isRemember = localStorage.getItem('isRemember') === 'true';

    localStorage.clear();

    Object.keys(deviceFingerprintsToKeep).forEach(key => {
      localStorage.setItem(key, deviceFingerprintsToKeep[key]);
    });

    if (isRemember && deviceFingerprintsToKeep[currentFingerprint]) {
      localStorage.setItem(currentFingerprint, deviceFingerprintsToKeep[currentFingerprint]);
    } else if(!isRemember) {
      localStorage.removeItem(currentFingerprint);
    }

    window.location.reload()
  }

  setUserInfo(user_info: IUserInfo) {
    localStorage.setItem('user_info', JSON.stringify(user_info));
  }

  getUserInfo() {
    const userData = localStorage.getItem('user_info');
    if (userData !== null) {
      return JSON.parse(userData) as IUserInfo;
    }
    return null;
  }

  checkPassword(data: { password: string }) {
    return this.http.post(this.url + 'api/v1/users/check-password', data);
  }

  setEmail(email: string) {
    localStorage.setItem('email', email);
  }

  getEmail() {
    const email = localStorage.getItem('email');
    if (email !== null) {
      return email;
    }
    return '';
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem('accessToken', accessToken);
  }

  setTempToken(token: string) {
    localStorage.setItem('token', token);
  }

  getTempToken() {
    return localStorage.getItem('token');
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  isLoggedIn() {
    return this.getAccessToken() != null;
  }


  getFingerPrintId(email: string) {
    const storedFingerprint = localStorage.getItem(email+'deviceFingerprint');
    return storedFingerprint;
  }
  setFingerPrintId(email: string, fingerPrintId: string) {
    localStorage.setItem(email + 'deviceFingerprint', fingerPrintId);
  }

  getIsRemember() {
    const isRemember = localStorage.getItem('isRemember');
    return isRemember;
  }
  setIsRemember() {
    localStorage.setItem('isRemember', 'true');
  }
}
