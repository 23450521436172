import { Component } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-help-center-dialog',
  templateUrl: './help-center-dialog.component.html',
  styleUrls: ['./help-center-dialog.component.scss'],
  providers: [DialogService],
})
export class HelpCenterDialogComponent {
  title = '';
  body = '';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.title = this.dialogConfig.data?.title;
    this.body = this.dialogConfig.data?.message;
  }

  close() {
    this.ref.close()
  }
}
