import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, of, switchMap, tap } from 'rxjs';
import {
  DisbursementHistoryDetailFactoryService,
  DisbursementHistoryDetailState,
} from '../factory/disbursement-history-detail-store-factory';
import { DisbursementHistoryDetailApiService } from '../services/disbursement-history-detail.api.service';

const initialState: DisbursementHistoryDetailState = {
  errorMessage: '',
  isLoading: false,
  currentDisbursementHistory: null,
  downloadUrl: null,
};

@Injectable()
export class DisbursementHistoryDetailStore
  extends ComponentStore<DisbursementHistoryDetailState>
  implements DisbursementHistoryDetailFactoryService
{
  constructor(
    private disbursementHistoryDetailApiService: DisbursementHistoryDetailApiService
  ) {
    super(initialState);
  }

  errorMessage$ = this.select((state) => state.errorMessage);
  isLoading$ = this.select((state) => state.isLoading);
  currentDisbursementHistory$ = this.select(
    (state) => state.currentDisbursementHistory
  );

  vm$ = this.select((state) => state);

  readonly requestDisbursementHistory = this.effect<string>((id$) =>
    id$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap((id) =>
        this.disbursementHistoryDetailApiService
          .getDisbursementHistoryDetail(id)
          .pipe(
            tap({
              next: (response) => {
                this.patchState({
                  currentDisbursementHistory: response.data,
                  isLoading: false,
                });
              },
              error: (error) => {
                this.patchState({
                  errorMessage: error.message,
                  isLoading: false,
                });
              },
            }),
            catchError((error) => {
              this.patchState({
                errorMessage: error.message,
                isLoading: false,
              });
              return of([]);
            })
          )
      )
    )
  );

  readonly downloadRecepit = this.effect<string>((id$) =>
    id$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap((id) =>
        this.disbursementHistoryDetailApiService.downloadReceipt(id).pipe(
          tap({
            next: (response) => {
              this.patchState({
                downloadUrl: response.data.receiptUrl,
                isLoading: false,
              });
            },
            error: (error) => {
              this.patchState({
                errorMessage: error.message,
                isLoading: false,
              });
            },
          }),
          catchError((error) => {
            this.patchState({
              errorMessage: error.message,
              isLoading: false,
            });
            return of([]);
          })
        )
      )
    )
  );
}
