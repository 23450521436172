import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@merchant-portal/environments/environment';
import { DialogComponentService } from 'projects/merchant-portal/src/app/components/dialog/dialog.component.service';
import {
  DisbursementResponse,
  DisbursementSummary,
  IPaymentResponse,
  PaymentOption,
} from 'projects/merchant-portal/src/app/models/disbursement/disbursement.interface';
import { take } from 'rxjs';
import {
  APPROVAL_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from '../transaction-history/constant/transaction-history.constant';
import { DisbursementService } from './services/disbursement.service';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.scss'],
})
export class DisbursementComponent implements OnInit {
  router = inject(Router);
  disbursementSummary: DisbursementSummary | undefined;
  paymentMethodList: {
    virtualAccount: PaymentOption[];
    bankTransfer: PaymentOption[];
    creditCard: PaymentOption[];
  } = {
    virtualAccount: [],
    bankTransfer: [],
    creditCard: [],
  };

  private todayDate = new Date(new Date().setHours(0, 0, 0, 0));
  private get endDate() {
    const lastDate = new Date(new Date().setDate(this.todayDate.getDate() + 1));
    lastDate.setHours(23, 59, 59, 999);
    return lastDate;
  }

  totalDisbursementTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
      },
    })
    .toString();

  totalSuccessTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        transactionStatus: TRANSACTION_STATUS.SUCCESS,
      },
    })
    .toString();

  totalPendingTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        transactionStatus: TRANSACTION_STATUS.PENDING,
      },
    })
    .toString();

  totalFailedTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        transactionStatus: TRANSACTION_STATUS.FAILED,
      },
    })
    .toString();

  totalWaitingForApprovalSingleTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        status: APPROVAL_STATUS.WAITING,
        type: TRANSACTION_TYPE.SINGLE,
      },
    })
    .toString();

  totalWaitingForApprovalBulkTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        status: APPROVAL_STATUS.WAITING,
        type: TRANSACTION_TYPE.BULK,
      },
    })
    .toString();

  totalApprovedTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        status: APPROVAL_STATUS.APPROVED,
      },
    })
    .toString();

  totalRejectedTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        status: APPROVAL_STATUS.REJECTED,
      },
    })
    .toString();

  topUpSingleLink = this.router
    .createUrlTree([`/disbursement/approval/single-transaction-retry`])
    .toString();

  topUpBulkLink = this.router
    .createUrlTree([`/disbursement/approval/bulk-transaction-retry`])
    .toString();

  get totalBalance() {
    return (
      parseFloat(this.disbursementSummary?.availableBalance?.value ?? '0') +
      parseFloat(this.disbursementSummary?.pendingBalance?.value ?? '0')
    );
  }

  constructor(
    private dialogComponentService: DialogComponentService,
    private disbursementService: DisbursementService
  ) {}

  ngOnInit(): void {
    this.getListPaymentMethods();
    this.getListDisbursementSummary();
  }

  showTopUpDialog() {
    if (environment.topUpSimulation) {
      this.router.navigateByUrl('/disbursement/top-up-simulation');
    } else {
      const content = [
        ...(this.paymentMethodList?.virtualAccount ?? []),
        ...(this.paymentMethodList?.bankTransfer ?? []),
      ];

      this.dialogComponentService.openTopUpDialog({
        width: '1000px',
        height: '700px',
        body: {
          title: 'Top Up Balance',
          content: [...content],
        },
      });
    }
  }

  getListDisbursementSummary() {
    this.disbursementService
      .getDisbursementSummary()
      .pipe(take(1))
      .subscribe((res: DisbursementResponse) => {
        if (res.data) {
          this.disbursementSummary = res?.data;
        }
      });
  }

  getListPaymentMethods() {
    this.disbursementService
      .getPaymentMethods('disbursement_top_up')
      .pipe(take(1))
      .subscribe((res: IPaymentResponse) => {
        if (res.data) {
          this.paymentMethodList = res?.data;
        }
      });
  }
}
