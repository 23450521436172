import { Component } from '@angular/core';

@Component({
  selector: 'app-account-blocked',
  templateUrl: './account-blocked.component.html',
  styleUrls: ['./account-blocked.component.scss','../auth.scss']
})
export class AccountBlockedComponent {

}
