import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { passwordValidator } from '../guard/password.guard.validator';
import { catchError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponentService } from '../../components/dialog/dialog.component.service';

@Component({
  selector: 'app-change-forgot-password',
  templateUrl: './change-forgot-password.component.html',
  styleUrls: ['./change-forgot-password.component.scss', '../auth.scss'],
})
export class ChangeForgotPasswordComponent {
  currentPasswordForm: FormGroup;
  newPasswordForm: FormGroup;
  isCurrentPasswordValid = false;
  errorMessage = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialogComponentService: DialogComponentService
  ) {
    this.currentPasswordForm = this.createPasswordForm(true); // Pass true to make new_password not required
    this.newPasswordForm = this.createPasswordForm(false); // Pass false to make new_password required
  }

  private createPasswordForm(newPasswordOptional: boolean) {
    return this.fb.group({
      password: !newPasswordOptional
        ? ['']
        : ['', [Validators.required, passwordValidator()]],
      new_password: newPasswordOptional
        ? ['']
        : ['', [Validators.required, passwordValidator()]],
    });
  }

  onSubmitCurrentPassword() {
    this.handleSubmit(this.currentPasswordForm, false);
  }

  onSubmitNewPassword() {
    this.handleSubmit(this.newPasswordForm, true);
  }

  private handleSubmit(form: FormGroup, isComplete: boolean) {
    this.authService
      .changePassword(form.getRawValue())
      .pipe(
        catchError(({ error }: HttpErrorResponse) => {
          this.errorMessage = error.message;
          throw error;
        })
      )
      .subscribe((res: any) => {
        if (!isComplete) {
          this.errorMessage = '';
          this.isCurrentPasswordValid = true;
        } else {
          this.showSuccessDialog();
        }
      });
  }

  showSuccessDialog() {
    this.dialogComponentService.openSuccessDialog({
      width: '700px',
      height: '500px',
      body: {
        title: 'Password Changed',
        message:
          'Your password has been successfully updated. Please login again.',
      },
    });
  }
}
