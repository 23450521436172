<div class="success-dialog">
  <div class="content-wrap">
    <div class="title-wrap">
      <i class="pi pi-plus"></i>
      <h2>{{ title }}</h2>
    </div>
    <form [formGroup]="submitForm" (ngSubmit)="onSubmit()">
      <div class="input-form">
        <label for="email">Email</label>
        <input pInputText id="email" formControlName="email" type="text" />
        <div class="warning-message">
          <div
            *ngIf="
              submitForm.get('email')?.hasError('required') &&
              submitForm.get('email')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Please enter email
          </div>
          <div *ngIf="submitForm.get('email')?.hasError('email')">
            <i class="pi pi-exclamation-triangle"></i> Invalid email format.
          </div>
        </div>
      </div>
      <div class="input-form">
        <label for="name">Name</label>
        <input pInputText id="name" formControlName="name" type="text" />
        <div class="warning-message">
          <div
            *ngIf="
              submitForm.get('name')?.hasError('required') &&
              submitForm.get('name')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Please enter name
          </div>
        </div>
      </div>
      <div class="input-form">
        <label for="role">Role</label>
        <p-dropdown
          id="role"
          formControlName="role"
          [options]="roles"
          optionLabel="name"
          placeholder="Select"
          [optionValue]="'value'"
        ></p-dropdown>
        <div class="warning-message">
          <div
            *ngIf="
              submitForm.get('role')?.hasError('required') &&
              submitForm.get('role')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Please enter role
          </div>
        </div>
      </div>
      <div *ngIf="!id" class="input-form">
        <label for="password">Password</label>
        <input
          pInputText
          id="password"
          formControlName="password"
          type="password"
        />
        <div class="warning-message">
          <div
            *ngIf="
              submitForm.get('password')?.hasError('required') &&
              submitForm.get('password')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Please enter password
          </div>
          <div
            *ngIf="
              submitForm.get('password')?.hasError('invalidPassword') &&
              submitForm.get('password')?.value
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Password must have at
            least 8 characters, including uppercase, lowercase, number, and
            symbol.
          </div>
        </div>
      </div>
      <div *ngIf="!id" class="input-form">
        <label for="confirmPassword">Re-enter Password</label>
        <input
          pInputText
          id="confirmPassword"
          formControlName="confirmPassword"
          type="password"
        />
        <div class="warning-message">
          <div
            *ngIf="
              submitForm.get('confirmPassword')?.hasError('required') &&
              submitForm.get('confirmPassword')?.touched
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Please re-enter password
          </div>
          <div
            *ngIf="
              submitForm.get('confirmPassword')?.value !==
              submitForm.get('password')?.value
            "
          >
            <i class="pi pi-exclamation-triangle"></i> Password do not match.
          </div>
        </div>
      </div>
      <div class="wrap-button">
        <button (click)="close()" class="pg-button invert-button">
          Cancel
        </button>
        <button
          *ngIf="!id"
          pButton
          [disabled]="submitForm.invalid"
          type="submit"
          class="pg-button create-button"
        >
          Send Invitation
        </button>
        <button
          *ngIf="id"
          pButton
          [disabled]="
            submitForm.get('email')?.invalid ||
            submitForm.get('name')?.invalid ||
            submitForm.get('role')?.invalid
          "
          type="submit"
          class="pg-button edit-button"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<app-block-loading
  [show]="loading$ | async | defaultValue : false"
></app-block-loading>
