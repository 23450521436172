import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ISidebar } from '@merchant-portal/app/models/sidebar/sidebar.interface';
import { environment } from '@merchant-portal/environments/environment';

export interface MenuAndPermissionsResponse {
  code: string;
  message: string;
  data: MenuAndPermissionsData[];
}

export interface MenuAndPermissionsData {
  type?: string;
  uuid: string;
  slug: string;
  name: string;
  icon: string;
  path: string;
  level: number;
  order: number;
  parentId: string | null;
  createdAt: string;
  updatedAt: string;
  permissions: Permission[];
  children: MenuAndPermissionsData[];
}

interface Permission {
  group: string;
  slug: string;
}

@Injectable()
export class MenuAndPermissionApiService {
  static mapApiResponseToSidebar(menus: MenuAndPermissionsData[]) {
    throw new Error('Method not implemented.');
  }
  url = environment.backend_portal_url;
  http = inject(HttpClient);

  getMenuAndPermissions() {
    return this.http.get<MenuAndPermissionsResponse>(
      this.url + 'api/v1/menus/role'
    );
  }

  static mapMenuItemToSidebar(menuItem: MenuAndPermissionsData): ISidebar {
    return {
      label: menuItem.name,
      routerLink: menuItem.path,
      icon: menuItem.icon !== '-' ? menuItem.icon : undefined,
      customIcon: menuItem.icon !== '-' ? menuItem.icon : undefined,
      isActive: false,
      children: menuItem.children
        ? menuItem.children.map((child) => ({
            ...MenuAndPermissionApiService.mapMenuItemToSidebar(child),
            icon: '-',
            customIcon: '-',
          }))
        : undefined,
      permissions: menuItem.permissions.map((permission) => permission.slug),
      hidden: false,
    };
  }
}
