// password.validator.ts

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value || '';

    // Check if the password meets the criteria
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    // eslint-disable-next-line no-useless-escape
    const hasSymbol = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value);

    const isValid =
      value.length >= 8 &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSymbol;

    return isValid ? null : { invalidPassword: true };
  };
}
