<div class="app-bar">
  <img height="50px" [src]="'./assets/images/iki.png'" alt="" />
</div>

<p-card>
  <div>
    <div class="p-card-title">Create PIN</div>
    <div class="p-card-subtitle">
      {{
        isComparing
          ? "Please re-enter your PIN"
          : "Please create a 6-digit number PIN"
      }}
    </div>
  </div>

  <code-input
    #codeInput
    class="pin-input invalid"
    [isCodeHidden]="true"
    [codeLength]="6"
    [isCharsCode]="false"
    (codeChanged)="onCodeChanged($event)"
    [ngClass]="{
      'invalid': (error$ | async)
    }"
    
  >
  </code-input>
  <p  class="warning-message" *ngIf="error$ | async">
    <i class="pi pi-exclamation-triangle"></i>    {{ error$ | async }}
  </p>
  <button class="pg-button" (click)="next()">Next</button>
</p-card>
