import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingService } from './services/loading.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';



@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule, ProgressSpinnerModule,
  ],
  providers: [LoadingService],
  exports:[LoadingComponent]
})
export class LoadingModule { }
