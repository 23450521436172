import { Pipe, type PipeTransform } from '@angular/core';
import { ITransactionHistoryDetailResponse } from '@merchant-portal/app/models/transaction-history/transaction-history-detail/transaction-history-detail.interface';
import { ITransactionHistoryResponse } from '@merchant-portal/app/models/transaction-history/transaction-history.interface';
import {
  TRANSACTION_CHANNEL,
  TRANSACTION_TYPE,
} from '../constant/transaction-history.constant';

type TransactionHistoryItem =
  | ITransactionHistoryResponse['data'][0]
  | ITransactionHistoryDetailResponse['data'];

@Pipe({
  name: 'appTransactionType',
  standalone: true,
})
export class TransactionTypePipe implements PipeTransform {
  transform(value: TransactionHistoryItem): string {
    if (value.type === TRANSACTION_TYPE.MANUAL_ADJUSTMENT) {
      if (value.channel === TRANSACTION_CHANNEL.MANUAL_TRANSFER) {
        return TRANSACTION_TYPE.MANUAL_TOP_UP;
      } else if (value.channel === TRANSACTION_CHANNEL.BALANCE_ADJUSTMENT) {
        return TRANSACTION_TYPE.BALANCE_ADJUSTMENT;
      }

      return value.type;
    } else {
      const detailValue: ITransactionHistoryDetailResponse['data'] =
        value as ITransactionHistoryDetailResponse['data'];
      if (detailValue?.details?.bulkId) {
        return TRANSACTION_TYPE.BULK_DISBURSEMENT;
      }
      return value.type;
    }
  }
}
