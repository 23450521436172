import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AuthService } from '../../auth.service';

export const pinExistGuard: CanActivateFn = (_route, _state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.getUserInfo()?.isEmptyPin) {
    router.navigateByUrl('/create-pin');
    return false;
  }

  return true;
};

export const emptyPinGuard: CanActivateFn = (_route, _state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.getUserInfo()?.isEmptyPin) {
    router.navigateByUrl('/');
    return false;
  }

  return true;
};
