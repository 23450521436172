import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { DashboardAction } from '@merchant-portal/app/features/dashboard/store/dashboard.action';
import { StompService } from '@merchant-portal/app/services/stomp/stomp.service';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  imports: [ToastModule, CommonModule],
  standalone: true,
})
export class NotificationComponent implements OnInit {
  userId = '';
  isConnected = false;

  constructor(
    private stompService: StompService,
    private messageService: MessageService,
    private authService: AuthService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserInfo()?.uuid ?? '';
    if (!this.userId) {
      console.error('User ID is required to subscribe to notifications');
      return;
    }

    this.stompService
      .watchUserNotifications(this.userId)
      .subscribe((message) => {
        try {
          const notification = JSON.parse(message.body); // Ensure the message is JSON
          this.messageService.add({
            key: 'notification',
            data: {
              title: notification.subject,
              body: notification.message,
            },
            life: 3000, // Display duration in milliseconds
          });
          this.store.dispatch(
            DashboardAction.setNotification({ status: false })
          );
        } catch (error) {}
      });

    this.stompService.connectionState$.subscribe((state) => {
      this.isConnected = state === 3; // 3 is the OPEN state
    });
  }
}
