import { Meta } from '@merchant-portal/app/models/disbursement/disbursement.interface';
import {
  IRole,
  IRolePermissionData,
} from '@merchant-portal/app/models/user-role/role.interface';
import { createFeature, createReducer, on } from '@ngrx/store';
import { viewRoleAction } from './action';

export interface IRolesState {
  rolesList: IRole[];
  pagination: Meta;
  loading: boolean;
  rolePermissionDetail: IRolePermissionData[];
  error: any;
}

const initialState: IRolesState = {
  rolesList: [],
  loading: false,
  error: null,
  pagination: { page: 1, perPage: 0, totalItems: 0, totalPages: 0 },
  rolePermissionDetail: [],
};

const reducer = createReducer(
  initialState,
  on(
    viewRoleAction.requestRoleList,
    (state: IRolesState): IRolesState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    viewRoleAction.requestRoleListSuccess,
    (state: IRolesState, { data, meta }): IRolesState => ({
      ...state,
      rolesList: data,
      loading: false,
      pagination: meta,
    })
  ),
  on(
    viewRoleAction.requestRoleListFailed,
    (state: IRolesState, { error }): IRolesState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    viewRoleAction.requestDeleteRole,
    (state: IRolesState): IRolesState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    viewRoleAction.requestDeleteRoleSuccess,
    (state: IRolesState): IRolesState => ({
      ...state,
      loading: false,
    })
  ),
  on(
    viewRoleAction.requestDeleteRoleFailed,
    (state: IRolesState, { error }): IRolesState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    viewRoleAction.requestShowRoleDetail,
    (state: IRolesState): IRolesState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    viewRoleAction.requestShowRoleDetailSuccess,
    (state: IRolesState, { data }): IRolesState => ({
      ...state,
      loading: false,
      rolePermissionDetail: data,
    })
  ),
  on(
    viewRoleAction.requestShowRoleDetailFailed,
    (state: IRolesState, { error }): IRolesState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const viewRolesFeature = createFeature({
  name: 'View Roles',
  reducer: reducer,
});
