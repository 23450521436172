import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IBulkTransactionResponse, BulkTransactionFilter } from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/bulk-transaction.interface';

@Injectable()
export abstract class BulkTransactionFactoryService {
  abstract isLoading$: Observable<boolean>;
  abstract currentBulkTransaction$: Observable<IBulkTransactionResponse['data']>;
  abstract currentBulkTransactionPagination$: Observable<IBulkTransactionResponse['meta']>;
  abstract currentBulkTransactionFilter$: Observable<Partial<BulkTransactionFilter>>;
  abstract requestBulkTransaction(param: Partial<BulkTransactionFilter>): void;
}
