import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  effect,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface ISinggleFormHandler {
  error: Signal<string | null>;
  result: Signal<string>;
  validate: (value: string) => void;
  isValid?: (value: string) => boolean;
  inputType: string;
}

export interface ISinggleFormDialogData {
  title: string;
  subtitle: string;
  buttonText: string;
  formLabel: string;
  store?: ISinggleFormHandler;
}

@Component({
  selector: 'app-singgle-form-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './singgle-form-dialog.component.html',
  styleUrls: ['./singgle-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SinggleFormDialogComponent {
  title = '';
  subtitle = '';
  buttonText = '';
  formLabel = '';
  formValue = '';
  store: ISinggleFormHandler | undefined = undefined;
  isValid = false;
  private ref: DynamicDialogRef = inject(DynamicDialogRef);
  errorText = computed(() => this.store?.error() ?? '');
  lastErrorValue = computed(() => {
    return this.errorText() ? this.formValue : '';
  });

  shouldShowError = computed(() => {
    return this.errorText() && this.formValue === this.lastErrorValue();
  });

  constructor(
    private dialogConfig: DynamicDialogConfig<ISinggleFormDialogData>
  ) {
    this.title = this.dialogConfig.data?.title ?? '';
    this.subtitle = this.dialogConfig.data?.subtitle ?? '';
    this.buttonText = this.dialogConfig.data?.buttonText ?? '';
    this.formLabel = this.dialogConfig.data?.formLabel ?? '';
    this.store = this.dialogConfig.data?.store;
    effect(() => {
      if (this.store?.result()) {
        setTimeout(() => {
          this.ref.close(this.store?.result());
        });
      }
    });
  }

  onChange(value: string) {
    if (this.store?.isValid) {
      if (this.store?.isValid(value)) {
        this.isValid = true;
        this.formValue = value;
      }
    } else {
      this.isValid = true;
      this.formValue = value;
    }
  }

  close() {
    this.store?.validate(this.formValue);
  }
}
