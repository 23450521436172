import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IDisbursementHistoryDetailResponse,
  IDisbursementHistoryReceiptResponse,
} from '@merchant-portal/app/models/disbursement/disbursement-history/disbursement-history.interface';
import { environment } from '@merchant-portal/environments/environment';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DisbursementHistoryDetailApiService {
  public backend_portal_url =
    environment.backend_portal_url + 'api/v1/disbursements/';

  constructor(private http: HttpClient) {}

  getDisbursementHistoryDetail(
    id: string
  ): Observable<IDisbursementHistoryDetailResponse> {
    return this.http
      .get<IDisbursementHistoryDetailResponse>(this.backend_portal_url + id)
      .pipe(
        catchError((val) => {
          return of(val);
        })
      );
  }

  downloadReceipt(id: string): Observable<IDisbursementHistoryReceiptResponse> {
    return this.http
      .get<IDisbursementHistoryReceiptResponse>(
        this.backend_portal_url + id + '/receipt'
      )
      .pipe(
        catchError((val) => {
          return of(val);
        })
      );
  }
}
