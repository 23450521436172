import { Injectable, Signal } from '@angular/core';

@Injectable()
export abstract class ChangePinStoreFactory {
  abstract loading$: Signal<boolean>;
  abstract error$: Signal<string | null>;
  abstract userStep: Signal<
    'validate-pin' | 'input-new-pin' | 'confirm-new-pin' | 'done'
  >;
  abstract next(pin: string): void;
}
