import { Component, OnDestroy, OnInit } from '@angular/core';
import { BeneficiaryListFilter } from 'projects/merchant-portal/src/app/models/disbursement/beneficiary-list/beneficiary-list.interface';
import { Subject, takeUntil } from 'rxjs';
import { BeneficiaryListFactoryService } from './factory/beneficiary-list-store-factory';

@Component({
  selector: 'app-beneficiary-list',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss'],
})
export class BeneficiaryListComponent implements OnInit, OnDestroy {
  datas: BeneficiaryListFactoryService['currentBeneficiaryList$'];
  totalRecord =
    this.beneficiaryListFactoryService.currentBeneficiaryListPagination$;
  status = [];
  types = [];
  dateFilter: Date[] = [];
  createdDate: any;
  selectedType = '';
  selectedStatus = '';
  ngUnSubcribe = new Subject<boolean>();
  filter: Partial<BeneficiaryListFilter> = {};

  constructor(
    private beneficiaryListFactoryService: BeneficiaryListFactoryService
  ) {
    this.beneficiaryListFactoryService.currentBeneficiaryListFilter$
      .pipe(takeUntil(this.ngUnSubcribe))
      .subscribe((value) => {
        if (value.startCreatedAt && value.endCreatedAt) {
          this.dateFilter = [
            new Date(value.startCreatedAt),
            new Date(value.endCreatedAt),
          ];
        }
        this.filter.beneficiaryAccountNo = value.beneficiaryAccountNo;
        this.filter.beneficiaryAccountName = value.beneficiaryAccountName;
        this.filter.page = value.page;
      });
    this.datas = this.beneficiaryListFactoryService.currentBeneficiaryList$;
  }

  ngOnInit(): void {
    console.log('BeneficiaryListComponent initialized');
  }

  ngOnDestroy(): void {
    this.ngUnSubcribe.complete();
  }

  onFilterChange() {
    this.beneficiaryListFactoryService.requestBeneficiaryList({
      ...this.filter,
      page: 0,
    });
  }

  onPageChange(event: number) {
    if (event !== undefined) {
      this.beneficiaryListFactoryService.requestBeneficiaryList({
        ...this.filter,
        page: event + 1,
      });
    }
  }

  onFilterDateChange(event: Date[]) {
    if (event[1]) {
      this.filter.startCreatedAt = event[0];
      this.filter.endCreatedAt = event[1];
    }
  }

  clearField(fieldName: keyof BeneficiaryListFilter): void {
    this.filter[fieldName] = undefined;
    if (fieldName == 'startCreatedAt') {
      this.dateFilter = [];
      this.filter['endCreatedAt'] = undefined;
    }
  }
}
