<div class="transaction-dialog">
  <div class="content-wrap">
    <div class="title-wrap">
      <h3>{{ title }}</h3>
      <span [innerHTML]="subTitle"></span>
    </div>
    <div class="bottom-container">
      <div *ngFor="let item of content; let lastItem = last">
        <div class="bottom-div">
          <div class="bottom-item" style="text-align: left">
            <div>
              <span [ngClass]="'status' + item.result">{{ item.result }}</span>
            </div>
            <span>Count</span>
            <span>Amount</span>
            <span>Fee</span>
            <span>Total Amount</span>
          </div>
          <div class="bottom-item" style="text-align: right; color: black">
            <span>{{ item.count }}</span>
            <span>{{
              item.amount | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
            <span>{{
              item.fee | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
            <span style="font-weight: 700">{{
              item.totalAmount | currency : "IDR" : "symbol" : "1.0-0" : "id"
            }}</span>
          </div>
        </div>
        <hr width="90%" style="margin-bottom: -15px" *ngIf="!lastItem" />
      </div>
    </div>
    <div class="wrap-button">
      <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
      <button (click)="confirm()" class="pg-button">Confirm</button>
    </div>
  </div>
</div>
